<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="saveSegmentation">
      <!-- departments -->
      <b-row>
        <b-col md="12">
          <b-form-group
            label-for="account-departments"
            label="Departments"
          >
            <v-select
              v-model="segmentationLocal.departments"
              :options="departments"
              :class="isNotEntityAdmin ? 'select-disabled': ''"
              multiple
              :select-size="4"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- average retail price -->
        <b-col md="6">
          <b-form-group
            label-for="account-avg-retail-price"
            label="Average Retail Price"
          >
            <v-select
              v-model="segmentationLocal.avgRetailPrice"
              :options="retailPrices"
              :disabled="isNotEntityAdmin"
            />
          </b-form-group>
        </b-col>
        <!-- average wholesale price -->
        <b-col md="6">
          <b-form-group
            label-for="account-avg-wholesale-price"
            label="Average Wholesale Price"
          >
            <v-select
              v-model="segmentationLocal.avgWholesalePrice"
              :options="wholesalePrices"
              :disabled="isNotEntityAdmin"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isBrand">
        <b-col md="6">
          <b-form-group
            label-for="account-minimum-order-value"
            :label="'Minimum Order Value ('+profileData.currency+')'"
          >
            <b-form-input
              v-model="segmentationLocal.minimumOrderValue"
              type="number"
              placeholder="Minimum Order Value"
              :class="{'disabled-input': isNotEntityAdmin}"
              :disabled="isNotEntityAdmin"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="exclude-countries"
            label="Exclude Countries"
          >
            <template #label>
              <div class="d-flex justify-content-between">
                <span class="d-flex">
                  Exclude Countries
                  <b-form-checkbox
                    v-model="selectAllCountries"
                    class="ml-50"
                    :disabled="isNotEntityAdmin"
                    @change="handleSelectAllCountries"
                  >
                    {{ selectAllCountries ? 'Deselect': 'Select' }} All
                  </b-form-checkbox>
                </span>
                <span class="badge badge-pill badge-light-primary elements-center">
                  {{ segmentationLocal.restrictedCountries.length }} Selected
                </span>
              </div>
            </template>
            <custom-select
              v-model="segmentationLocal.restrictedCountries"
              :option-groups="countryOptionGroups"
              placeholder="Select Country"
              :multiple="true"
              :clear-search-on-select="false"
              :close-on-select="false"
              no-data-text="No country found."
              @input="isAllCountrySelected"
              @search="setCountries"
              @select="handleSelectCountry"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Collection Schedule -->
      <account-collection-schedule v-if="isBrand" />

      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :class="{'cursor-not-allowed': isNotEntityAdmin}"
            :disabled="loading || isNotEntityAdmin"
            @click="saveSegmentation"
          >
            Save changes
            <feather-icon
              v-if="loading"
              icon="LoaderIcon"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            :class="{'mt-2': true,'cursor-not-allowed': isNotEntityAdmin}"
            :disabled="isNotEntityAdmin"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import analytics from '@/@core/utils/analytics'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import constants, { retailPrices, wholesalePrices } from '@/constants'
import {
BButton,
BCard,
BCol,
BForm,
BFormCheckbox,
BFormGroup,
BFormInput,
BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { UPDATE_ENTITY } from '@/store/modules/auth.module'
import { formatEntityData } from '@/utils/formatEntityData'
import CustomSelect from '@/@core/components/custom-select/CustomSelect.vue'
import UserRoleMixinVue from '../UserRoleMixin.vue'
import AccountCollectionSchedule from './AccountCollectionSchedule.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    CustomSelect,
    vSelect,
    AccountCollectionSchedule,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [UserRoleMixinVue],
  props: {
    segmentationData: {
      type: Object,
      default() {},
    },
    departments: {
      type: Array,
      default() {
        return []
      },
    },
    countries: {
      type: Array,
      default() {
        return []
      },
    },
    kingpinPresenceCountries: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      loading: false,
      segmentationLocal: this.buildDefault(),
      wholesalePrices,
      retailPrices,
      showCountries: false,
      allCountriesLocal: [],
      kingpinPresenceCountriesLocal: [],
      selectAllCountries: false,
    }
  },
  computed: {
    profileData() {
      return this.$store.getters.profile
    },
    isNotEntityAdmin() {
      return !this.$store.getters.isEntityAdmin
    },
    countryOptionGroups() {
      return [
        {
          title: 'Kingpin Presence',
          options: this.kingpinPresenceCountriesLocal,
        },
        {
          title: 'All Countries',
          options: this.allCountriesLocal,
        },
      ]
    },
  },
  created() {
    this.setCountries()
  },
  emits: ['update-segmentation'],
  methods: {
    buildDefault() {
      let segmentationLocal = JSON.parse(JSON.stringify(this.segmentationData))
      if (!segmentationLocal) {
        segmentationLocal = {
          avgWholesalePrice: null,
          avgRetailPrice: null,
          minimumOrderValue: null,
          departments: [],
          restrictedCountries: [],
        }
      }
      if (!segmentationLocal.departments) {
        segmentationLocal.departments = []
      }
      if (!segmentationLocal.restrictedCountries) {
        segmentationLocal.restrictedCountries = []
      }
      return segmentationLocal
    },
    resetForm() {
      this.segmentationLocal = this.buildDefault()
    },
    async saveSegmentation() {
      this.segmentationLocal.minimumOrderValue = parseInt(
        this.segmentationLocal.minimumOrderValue,
        10,
      )
      this.loading = true
      const entityData = formatEntityData(store.getters.entityData)
      const payload = {
        ...entityData,
        ...this.segmentationLocal,
      }
      store.dispatch(UPDATE_ENTITY, payload)
        .then(res => {
          apiToastSuccess(res.data.message || 'Profile updated successfully.')
          this.$emit('update-segmentation', this.segmentationLocal)
          analytics.track(constants.TRACKS.ACTIONS.SAVE_PROFILE_SETTINGS_SEGMENTATION)
        })
        .catch(err => {
          apiToastWarning(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    hideCountriesDropdown() {
      this.showCountries = false
    },
    setCountries(searchingText = '') {
            if (searchingText) {
        this.allCountriesLocal = this.countries.filter(country => country.toLowerCase()
            .indexOf(searchingText.toLowerCase()) !== -1)
        this.kingpinPresenceCountriesLocal = this.kingpinPresenceCountries.filter(country => country.toLowerCase()
            .indexOf(searchingText.toLowerCase()) !== -1)
      } else {
      this.allCountriesLocal = this.countries
      this.kingpinPresenceCountriesLocal = this.kingpinPresenceCountries
      this.isAllCountrySelected()
      }
    },
    handleSelectCountry(countries) {
      this.segmentationLocal.restrictedCountries = countries
      this.isAllCountrySelected()
    },
    handleSelectAllCountries() {
      if (this.selectAllCountries) {
        this.segmentationLocal.restrictedCountries = this.allCountriesLocal
      } else {
        this.segmentationLocal.restrictedCountries = []
      }
    },
    isAllCountrySelected(value) {
      if (value) {
        this.segmentationLocal.restrictedCountries = value
      }
      this.selectAllCountries = this.segmentationLocal.restrictedCountries.length === this.countries.length
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes

.countries-dropdown {
  .dropdown-menu {
    max-height: 300px !important;
    overflow-x: auto;
    .country-header {
      header {
        color: $primary !important;
        font-weight: 1000 !important;
      }
      background-color: $gray-600 !important;
    }
  }
}

.select-disabled {
  pointer-events: none;
  opacity: 0.65;
  background-color: #f8f8f8;
}

.select-disabled:after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    cursor: not-allowed;
    pointer-events: all !important;
}

.disabled-input {
  cursor: not-allowed;
}
</style>
