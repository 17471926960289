/* eslint-disable */
import JwtService, { getSwitchedUserId } from '@/store/jwt.service'
import authRequest from '@/auth'
import { kpRequest, kpEndpoint, kpParams } from '@/axios'
import Vue from 'vue'
import router from '@/router'

// action types
// export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const FORGET_PASSWORD = 'forgetPassword'
export const RESET_PASSWORD = 'resetPassword'
export const FETCH_PROFILE = 'fetchProfile'
export const GUEST_USER_LOGIN = 'guestUserLogin'
export const SWITCH_USER = 'switchUser'
export const SEND_LOGIN_LINK = 'sendLoginLink'
export const FETCH_USER_PROFILE = 'fetchUserProfile'
export const UPDATE_USER = 'updateUser'
export const UPDATE_USER_BY_ID = 'updateUserById'
export const ADD_NICK_NAME = 'addNickName'
export const UPDATE_NICK_NAME = 'updateNickName'
export const FETCH_STORES = 'fetchStores'
export const FETCH_SALES_REPS = 'fetchSalesReps'
export const FETCH_SALES_MANAGERS = 'fetchSalesManagers'
export const FETCH_SCOPES = 'fetchScopes'
export const FETCH_ALL_SCOPES = 'fetchAllScopes'
export const ADD_STORE = 'addStore'
export const UPDATE_ENTITY = 'updateEntity'

// mutation types
export const AUTH_ERROR = 'authError'
export const PURGE_AUTH = 'logOut'
export const SET_AUTHENTICATED = 'setAuthenticated'
export const SET_ROLE = 'setRole'
export const SET_ENTITY_TYPE = 'setEntityType'
export const SET_PROFILE = 'setProfile'
export const SET_LOGIN_USING_LINK = 'setLoginUsingLink'
export const SET_SALES_REPS = 'setSalesReps'
export const SET_SALES_MANAGERS = 'setSalesManagers'
export const SET_ALL_SCOPES = 'setAllScopes'
export const SET_TEAMMATES_ROLES = 'setTeammatesRoles'
export const SET_ENTITY_DATA = 'updateProfile'
export const SET_CURRENT_ENTITY_ID = 'setCurrentEntityId'

import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import {constants} from '@kingpin-global/kingpin-utils-frontend'
const { ROLES } = constants;
import { apiToastError } from '@/@core/utils/toast'
import { USER_ROLES } from '@/constants'
import { getAuthDetails, getAuthDetailsFromUserAndEntity } from '@/service/auth-middleware.service'
import { scopesFromUserAssociations, setEntityNameInUserObject } from '@/service/user.service'
import analytics from '@/@core/utils/analytics'
import {refreshTraceId} from "@/traceIdCookie";
import { lowerCase } from 'lodash'

const state = {
  isAuthenticated: !!JwtService.getToken(),
  role: '',
  entityType: '',
  currentEntityId: '',
  authError: null,
  profileData: null,
  entityData: null,
  isAdmin: JwtService.getAdmin(),
  isLoginUsingLink: false,
  salesReps: [],
  salesManagers: [],
  allScopes: [],
  teamMatesRoles: [],
};
const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated
    },
    authError(state) {
        return state.authError
    },
    role(state) {
        return state.role
    },
    entityType(state) {
        return state.entityType
    },
    currentEntityId(state) {
        return state.currentEntityId
    },
    entityData(state) {
        return state.entityData
    },
    profile(state) {
        return state.profileData
    },
    isDemo(state) {
        return state?.profileData?.isDemo
    },
    isBrand(state) {
        return state.entityType === ROLES.BRAND
    },
    isRetailer(state) {
        return state.entityType === ROLES.RETAILER
    },
    isEntityAdmin(state) {
        return state.role === USER_ROLES.ENTITYADMIN
    },
    isSalesManager(state) {
        return state.role === USER_ROLES.SALESMANAGER
    },
    isSalesRep(state) {
        return state.role === USER_ROLES.SALESREPRESENTATIVE
    },
    isAdmin(state) {
        return state.isAdmin
    },
    isGuest(state) {
        return state.entityType === ROLES.GUEST
    },
    isLoginUsingLink(state) {
        return state.isLoginUsingLink
    },
    teamMatesRoles(state) {
        return state.teamMatesRoles
    },
    getCurrentAssociation(state) {
       const switchedUserId = getSwitchedUserId()
       let currentAssociation = null
        if (switchedUserId) {
            currentAssociation = state.profileData?.userAssociations?.find(
              association => association.id === switchedUserId,
            )
          } else {
            const lastLoggedUserAssociationId = state?.profileData?.lastLoggedUserAssociationId
            if(lastLoggedUserAssociationId) {
                currentAssociation = state.profileData?.userAssociations?.find(
                    association => association.id === lastLoggedUserAssociationId,
                )
            } else {
                currentAssociation = state.profileData?.userAssociations[0]
            }
          }
        return currentAssociation
    },
}
const actions = {
    [LOGIN](context, payload) {
        return new Promise((resolve, reject) => {
            authRequest({...kpEndpoint.user.login, payload})
                .then(({ data }) => {
                    if (data.data.token) {
                        const authDetails = getAuthDetails(data.data)
                        JwtService.saveToken(authDetails.token)
                        JwtService.saveRole(authDetails.role)
                        JwtService.saveEntityType(authDetails.entityType)
                        Vue.prototype.$ability.update(JwtService.saveAbility())
                        context.commit(SET_ROLE)
                        context.commit(SET_ENTITY_TYPE)
                        context.commit(SET_AUTHENTICATED)
                        resolve(data)
                    }
                    else {
                        context.commit(PURGE_AUTH)
                        context.commit(AUTH_ERROR, data.message)
                        reject(data.message)
                    }
                })
                .catch(err => {
                    context.commit(AUTH_ERROR, err.response.data.message)
                    reject(err.response.data.message || err.message)
                })
        })
    },
    [REGISTER](context, payload) {
        return new Promise((resolve, reject) => {
            authRequest({...kpEndpoint.user.register, payload})
                .then(({ data }) => {
                    if (data) {
                        resolve(data)
                    }
                })
                .catch(({ response }) => {
                    reject(response.data.message || response.data.errors[0].message)
                })
        })
    },
    [FORGET_PASSWORD](context, payload) {
        return new Promise(resolve => {
            authRequest({...kpEndpoint.user.forgetPassword, payload})
                .then(({ data }) => {
                    Vue.prototype.$toast({
                        component: ToastificationContent,
                        props: {
                            title: data.message,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    resolve(data.data)
                })
                .catch(({ response }) => {
                    Vue.prototype.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'danger',
                        },
                    })
                })
        })
    },
    [RESET_PASSWORD](context, payload) {
        return new Promise(resolve => {
            authRequest({...kpEndpoint.user.resetPassword, payload})
                .then(({ data }) => {
                    Vue.prototype.$toast({
                        component: ToastificationContent,
                        props: {
                            title: data.message,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    resolve(data.data)
                })
                .catch(({ response }) => {
                    Vue.prototype.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'danger',
                        },
                    })
                })
        })
    },
    [SEND_LOGIN_LINK](context, payload) {
        return new Promise((resolve, reject) => {
            authRequest({...kpEndpoint.user.sendLoginLink , payload})
              .then(res => {
                context.commit(AUTH_ERROR, null)
                resolve(res.data)
              })
              .catch(err => {
                context.commit(AUTH_ERROR, err.response?.data?.message)
                reject(err)
              }) 
          })
    },
    [LOGOUT](context) {
        if (router.currentRoute.path !== '/dashboard/order-overview') {
            router.push({ path: `/login?redirect=${router.currentRoute.path}` })
        }
        else {
            router.push({ path: '/login' })
        }
        context.commit(PURGE_AUTH)
    },
    async [FETCH_PROFILE](context) {
        try {
            const userResponse = await kpRequest({...kpEndpoint.profile.get})
            let profileDetails, entityResponse

            if(userResponse) {
                const entityId = userResponse.data.data.currentEntityId
                const entityType = userResponse.data.data.entityType ?? userResponse.data.data.role
                entityResponse = await kpRequest({...kpParams(kpEndpoint.profile.getEntityDetails, lowerCase(entityType), entityId)})
            }

            if(userResponse && entityResponse) {
                context.commit(SET_ENTITY_DATA, entityResponse.data)
                profileDetails = getAuthDetailsFromUserAndEntity({
                    userData: userResponse.data.data,
                    entityData: entityResponse.data
                })
                JwtService.saveRole(profileDetails.role)
                JwtService.saveEntityType(profileDetails.entityType)
                context.commit(SET_CURRENT_ENTITY_ID, userResponse.data.data.currentEntityId)
                context.commit(SET_PROFILE, profileDetails) 
                context.commit(SET_ROLE)
                context.commit(SET_ENTITY_TYPE)
                context.dispatch(FETCH_ALL_SCOPES)
            }
            
            return Promise.resolve(profileDetails)
        } catch(err) {
            return Promise.reject(err)
        }
    },
    [GUEST_USER_LOGIN](context, payload) {
        return new Promise((resolve, reject) => {
            kpRequest({...kpEndpoint.guestUser.login, payload})
                .then(res => {
                    JwtService.saveToken(res.data.data.token)
                    JwtService.saveRole(ROLES.GUEST)
                    JwtService.saveEntityType(ROLES.GUEST)
                    context.commit(SET_ROLE)
                    context.commit(SET_ENTITY_TYPE)
                    context.commit(SET_AUTHENTICATED)
                    Vue.prototype.$ability.update(JwtService.saveAbility())
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SWITCH_USER](context, payload) {
        return new Promise((resolve, reject) => {
            kpRequest({...kpEndpoint.profile.switchUser, payload})
                .then(({ data }) => {
                    if (data.token) {
                        localStorage.clear();
                        const token = data.token
                        JwtService.saveToken(token)
                        JwtService.saveRole(payload.role)
                        JwtService.saveEntityType(payload.entityType)
                        Vue.prototype.$ability.update(JwtService.saveAbility())
                        context.commit(SET_ROLE)
                        context.commit(SET_ENTITY_TYPE)
                        context.commit(SET_AUTHENTICATED)
                        resolve(data)
                    }
                    else {
                        context.commit(PURGE_AUTH)
                        context.commit(AUTH_ERROR, data.message)
                        reject(data.message)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [FETCH_USER_PROFILE](ctx , force = false) {
        if(!force && ctx.state.profileData) {
            return ctx.state.profileData
        }
        return new Promise(resolve => {
            kpRequest({...kpEndpoint.profile.get})
                .then(res => {
                    return resolve(res.data.data)
                })
        })
    },
    async [UPDATE_USER](ctx, payload = {}) {
        await kpRequest({...kpEndpoint.profile.updateUser, payload})
    },
    async [ADD_STORE](ctx, payload = {}) {
       return await kpRequest({...kpEndpoint.profile.addStore, payload})
    },
    async [UPDATE_USER_BY_ID](ctx, {userId = "", payload = {}}) {
        await kpRequest({...kpParams(kpEndpoint.profile.updatedUserById, userId), payload})
    },
    async [ADD_NICK_NAME](ctx, { payload }) {
        return await kpRequest({ ...kpEndpoint.user.addNickName, payload })
    },
    async [UPDATE_NICK_NAME](ctx, { payload }) {
        return await kpRequest({ ...kpEndpoint.user.updateNickName, payload })
    },
    [FETCH_SALES_REPS](context) {
        context.commit(SET_SALES_REPS, [])
        if(context.getters.isBrand && (context.getters.isEntityAdmin || context.getters.isSalesManager)) {
            const salesReps = context.state.allScopes.filter(user => user.role === USER_ROLES.SALESREPRESENTATIVE)
            context.commit(SET_SALES_REPS, salesReps)
        }
    },
    async [FETCH_SCOPES](context, userId) {
       return await kpRequest({...kpParams(kpEndpoint.user.scopes, userId)})
    },
    [FETCH_SALES_MANAGERS](context) {
        context.commit(SET_SALES_MANAGERS, [])
        if(context.getters.isEntityAdmin || context.getters.isSalesManager) {
            const salesManagers = context.state.allScopes.filter(user => user.role === USER_ROLES.SALESMANAGER)
            context.commit(SET_SALES_MANAGERS, salesManagers)
        }
    },
    async [FETCH_ALL_SCOPES](context) {
        context.commit(SET_ALL_SCOPES, [])
        if(context.getters.isBrand || context.getters.isRetailer) {
            const currentUserId = context?.getters?.profile?.id
         await context.dispatch(FETCH_SCOPES, currentUserId)
                .then(res => {
                    const currentAssociation = context.getters.getCurrentAssociation
                    const associations = res?.data?.data?.userAssociations?.filter(association => association.id === currentAssociation?.id)
                    const userAssociations = associations?.map(association => ({
                        ...association,
                        userScope: association?.userScope?.map(scope => ({
                            ...scope,
                            userAssociationId: association?.id,
                        })),
                    }))
                    const allScopes = scopesFromUserAssociations(userAssociations) || []
                    const allScopesModified = allScopes.map(scope => ({
                        ...scope,
                        firstName: scope.user?.firstName,
                        lastName: scope.user?.lastName,
                        email: scope.user?.email,
                        _id: scope.user?.id,
                        // Below fields are used in the dropdowns
                        name: scope.user?.entityName || `${scope.user?.firstName} ${scope.user?.lastName}`,
                        userId: scope.user?.id,
                      }))
                    context.commit(SET_ALL_SCOPES, allScopesModified)
                    context.dispatch(FETCH_SALES_REPS)
                    context.dispatch(FETCH_SALES_MANAGERS)
                })
                .catch(err => {
                    apiToastError(err)
                }
            )
        }
     },
     async [UPDATE_ENTITY](context, payload) {
        try {
            const entityType = context.getters.entityType
            const currentEntityId = context.getters.currentEntityId
            const response = await kpRequest({
                ...kpParams(kpEndpoint.profile.updateEntityDetails, entityType, currentEntityId),
                payload
            })

            return Promise.resolve(response)
        } catch(error) {
            return Promise.reject(error)
        }
     }
}
export const mutations = {
    [SET_AUTHENTICATED](state) {
        state.isAuthenticated = true
    },
    setAdmin(state) {
        state.isAdmin = true
    },
    [SET_ROLE](state) {
        const role = JwtService.getRole()
        state.role = role
    },
    [SET_ENTITY_TYPE](state) {
        const entityType = JwtService.getEntityType()
        state.entityType = entityType
        this.commit(SET_TEAMMATES_ROLES)
    },
    [SET_TEAMMATES_ROLES](state) {
        let roleOptions = {}
        if (this.getters.isRetailer) {
          roleOptions = [USER_ROLES.ENTITYADMIN]
        } else if (this.getters.isEntityAdmin) {
          const options = USER_ROLES
          delete options.KINGPINADMIN
          roleOptions = options
        } else if (this.getters.isSalesManager) {
          const options = USER_ROLES
          delete options.ENTITYADMIN
          delete options.SALESMANAGER
          delete options.KINGPINADMIN
          roleOptions = options
        } 
        state.teamMatesRoles = roleOptions
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false
        state.isAdmin = null
        refreshTraceId(undefined);
        JwtService.destroyToken()
    },
    [AUTH_ERROR](state, error) {
        state.authError = error
    },
    [SET_PROFILE](state, payload) {
        if(payload) setEntityNameInUserObject(payload)
        state.profileData = payload
        refreshTraceId(payload?.email);
        // Set user in analytics
        analytics.setUserInAnalytics(payload)
    },
    [SET_ENTITY_DATA] (state, payload) {
        delete payload.createdAt
        delete payload.updatedAt
        delete payload.userAssociations
        delete payload.id

        state.entityData = payload
    },
    [SET_CURRENT_ENTITY_ID](state, id) {
        state.currentEntityId = id
    },
    [SET_SALES_REPS](state, data) {
        state.salesReps = data
    },
    [SET_SALES_MANAGERS](state, data) {
        state.salesManagers = data
    },
    [SET_ALL_SCOPES](state, data) {
        state.allScopes = data
    },
    [SET_LOGIN_USING_LINK](state, payload) {
        state.isLoginUsingLink = payload
    },
    restoreSession() {
        const token = JwtService.getToken()
        if (token) {
            this.commit(SET_ENTITY_TYPE)
            this.commit(SET_ROLE)
        }
        else {
            this.commit(PURGE_AUTH)
        }
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}