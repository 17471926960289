export default {
  PAGES: {
    SIGN_UP_SUCCESS: 'Sign up - Success page',
    SIGN_UP: 'Sign up page',
    SIGN_IN: 'Sign in page',
    BRAND_DASHBOARD: 'Brand Dashboard Page',
    RETAIL_DASHBOARD: 'Retailer Dashboard Page',
    ACCOUNT_SETTING_ADDRESS: 'Profile Settings Page - Addresses',
    ACCOUNT_SETTING_DOCUMENT: 'Profile Settings Page Documents',
    ACCOUNT_SETTING_GENERAL: 'Profile Settings Page - General',
    ACCOUNT_SETTING_PASSWORD: 'Profile Settings Page - Password',
    ACCOUNT_SETTING_SEGMENTATION: 'Profile Settings Page - Segmentation',
    ACCOUNT_SETTING_ADDITIONAL_USERS:
      'Profile Settings Page - Additional Users',
    ACCOUNT_SETTING_SOCIAL: 'Profile Settings Page - Social',
    WALLET: 'My Brands/My Retailers - Page',
    SHOP: 'Retailer - Product Shopping Page',
    ORDER_DETAILS: 'Order Details Page',
    BRAND_EXPLORE: 'Retailer - Brand Explore Page',
    BRAND_DETAILS: 'Retailer - Brand Profile Page',
    PRODUCT_DETAILS: 'Retailer - Product Details Page',
    BRAND_COLLECTIONS: 'Brand - Collections Page',
    USER_LOGGED_IN_USING_MAGIC_LINK: 'User logged in using magic link',
    // Updated on 04/10/2022
    // BRAND
    BRAND_OPENS_DASHBOARD: 'Brand - Clicks on dashboard icon',
    BRAND_OPENS_SELECTION: 'Brand - Opens selection page',
    BRAND_OPENS_COLLECTION: 'Brand - Opens collection page',
    BRAND_OPENS_ORDERS: 'Brand - Opens orders page',
    BRAND_OPENS_ORDER_DETAILS: 'Brand - Opens order details',
    BRAND_OPENS_TRANSACTIONS: 'Brand - Opens transactions page',
    BRAND_OPENS_SAVINGS: 'Brand - Opens savings page',
    BRAND_OPENS_REFERRAL_PAGE: 'Brand - Opens referrals page',
    BRAND_OPENS_MESSAGE_FROM: 'Brand opens a broadcast message from',
    BRAND_CLICKS_ANALYTICS: 'Brand - Clicks on analytics icon',
    BRAND_OPENS_ANALYTICS_PAGE: 'Brand - Opens analytics page',
    BRAND_EXITS_ANALYTICS_PAGE: 'Brand - Exits from analytics page',

    // Updated on 08/10/2022
    // Retailer
    RETAILER_OPENS_DASHBOARD: 'Retailer - Clicks on dashboard icon',
    RETAILER_OPENS_WISHLISTS: 'Retailer - Clicks on wishlist icon on side bar',
    RETAILER_OPENS_WISHLISTS_BY_DROPDOWN:
      'Retailer - Opens dropdown and selects a wishlist',
    RETAILER_OPENS_COLLECTION: 'Retailer - Opens collection page',
    RETAILER_OPENS_BASKET: 'Retailer - Opens cart page',
    RETAILER_OPENS_ORDERS: 'Retailer - Opens orders page',
    RETAILER_OPENS_ORDER_DETAILS: 'Retailer - Opens order details',
    RETAILER_OPENS_TRANSACTIONS: 'Retailer - Opens transactions page',
    RETAILER_OPENS_REWARDS: 'Retailer - Opens rewards page',
    RETAILER_OPENS_REFERRAL_PAGE: 'Retailer - Opens referrals page',
    RETAILER_CLICKS_BRAND_QUICKVIEW: 'Retailer clicks brand quickview',
    RETAILER_OPENS_BRANDS_PROFILE: "Retailer - Opens brand's profile",
    RETAILER_OPENS_WISHLIST_PRODUCTS: 'Retailer - View wishlist',
    RETAILER_OPENS_WISHLIST_PRODUCT: 'Retailer - View wishlist product',

    RETAILER_OPENS_CART_WITH_MULTIPLE_STORES:
      'Retailer - Opens cart with multiple stores',

    BRAND_OPENS_LEAD_AI_LIST: 'Leads AI - brand opens Listing Page',
    BRAND_OPENS_LEAD_AI_DETAIL: 'Leads AI - brand opens Detail Page',
  },
  ACTIONS: {
    PLACE_ORDER: 'Place Order Action',
    VIEW_IN_CART: 'View in Cart Action',
    ADD_TO_CART: 'Add to Cart Action',
    BACK_TO_COLLECTION: 'Back to Collection Action',
    ADD_MULTI_CART: 'Add Multi Cart Action',
    REMOVE_FROM_CART: 'Remove from Cart Action',
    SAVE_PROFILE_SETTINGS_SOCIAL: 'Save Profile Settings - Social',
    UPDATE_ADDRESS_PROFILE: 'Update Address - Profile Settings',
    DELETE_ADDRESS_PROFILE: 'Delete Address - Profile Settings',
    CREATE_ADDRESS_PROFILE: 'Create Address - Profile Settings',
    SAVE_PROFILE_SETTINGS_DOCUMENT: 'Save Profile Settings - Document',
    SAVE_PROFILE_SETTINGS_GENERAL: 'Save Profile Settings - General',
    SAVE_PROFILE_SETTINGS_PASSWORD: 'Save Profile Settings - Password',
    SAVE_PROFILE_SETTINGS_SEGMENTATION: 'Save Profile Settings - Segmentation',
    INVITE_ADDITIONAL_USERS_PROFILE:
      'Invite Additional Users - Profile Settings',
    DOWNLOAD_PRODUCT_IMAGES_ORDER: 'Download Product Images - Order',
    DOWNLOAD_PRODUCT_IMAGES_WISHLIST: 'Download Product Images - Wishlist',
    LOGIN: 'Login',
    REGISTER: 'Register',
    VIEW_COLLECTION: 'Collection View Action',
    BRAND_EXPORTS_CART_FOR_RETAILER: 'Brand Exports Cart For Retailer',
    CUSTOMER_CLICKS_WHATSAPP_TAB:
      "Customer - Clicks on whatsapp tab in 'get signin link' page",
    CUSTOMER_CHANGED_COUNTRY_CODE_LOGIN_PAGE:
      "Customer - Changed country code in 'get signin link' page",
    CUSTOMER_FILLED_THE_NUMBER:
      "Customer - Filled the number in 'get signin link' page",
    CUSTOMER_CLICKS_GET_SIGNIN_LINK_BUTTON:
      "Customer - Clicks on 'Get signin link' button in 'get signin link' page",
    SENT_SIGNIN_LINK_TO_CUSTOMER: 'Sent signin link to customer successfully',
    CUSTOMER_GET_ERROR_MESSAGE_WHEN_SUBMIT:
      "Customer - Get error message when submit phone number in 'get signin link' page",

    // Updated on 04/10/2022
    // Brand
    // Selection
    BRAND_SHARES_SELECTION: "Brand - Clicks on 'share' on simple share modal",
    BRAND_SHARES_SELECTION_WITH_REFERRAL:
      "Brand - Clicks on 'share' on referral share modal",
    BRAND_DELETES_SELECTION:
      "Brand - Clicks on 'delete selection' on selection delete modal",
    BRAND_REMOVES_SELECTED_ITEMS_FROM_SELECTION:
      "Brand - Selects items and clicks on actions -> 'remove from selection'",
    BRAND_CLICKS_ADD_SELECTION: 'Brand - Clicks add selection',
    BRAND_CHOOSES_COLLECTION_FOR_SELECTION:
      'Brand - Chooses collection for selection',
    // Collection
    BRAND_APPLIES_DELIVERY_DATES_FILTER:
      "Brand - Apply filters' on filter by delivery date",
    BRAND_SELECTS_GENERAL_VIEW: 'Brand - Clicks on view button = general',
    BRAND_SELECTS_COMPACT_VIEW:
      'Brand - Clicks on view option button = compact',
    BRAND_STARTS_ADDING_COLLECTION: "Brand - Clicks 'add collection'",
    BRAND_ENDS_ADDING_OR_EDITING_COLLECTION:
      "Brand - Clicks 'save' on 'add collection' or 'edit collection'",
    BRAND_COLLECTION_FILE_PROCEEDED: 'Brand - Collection file proceeded',
    BRAND_STARTS_EDITING_COLLECTION:
      "Brand - Clicks 3 dots -> 'edit collection'",
    BRAND_STARTS_UPDATING_AVAILABILITY_DATES:
      "Brand - Clicks three dots ->  'update availability'",
    BRAND_ENDS_UPDATING_AVAILABILITY_DATES:
      "Brand - Clicks 'save' on update availability pop-up",
    BRAND_AVAILABILITY_FILE_PROCEEDED: 'Brand - Availability file proceeded',
    BRAND_MOVES_SELECTED_ITEMS_TO_SELECTION:
      "Brand - Chooses dropdown option after selects items and clicks on actions -> 'move to selection'",
    BRAND_CLICKS_ON_VIEW_AS_RETAILER: "Brand - Clicks on 'view as retailer'",
    BRAND_CLICKS_ON_EXIT_RETAILER_VIEW:
      "Brand - Clicks on 'exit retailer view'",
    BRAND_SELECT_RETAILER: 'Brand - Select retailer',
    BRAND_CLOSES_CREATE_SELECTION_POPUP_WITHOUT_ACTION:
      "Brand - Closes 'create selection' popup without taking action",
    // Order
    BRAND_EXPORTS_ORDERS: "Brand - Clicks on 'export orders'",
    BRAND_DOWNLOADS_ORDER:
      "Brand - Clicks on 'download' on order download modal",
    BRAND_CHANGES_ORDER_STATUS: 'Brand - Changes order status',
    BRAND_MODIFIES_ORDER:
      "Brand - Clicks 'modify' and then saves changes - sends them for approval to retailer",
    // Shipment
    BRAND_DOWNLOADS_INVOICE: 'Brand - Downloads invoice',
    BRAND_UPLOADS_SHIPMENT_DOCUMENTS: 'Brand - Uploads shipment documents',
    BRAND_MARKS_SHIPMENT_COLLECTED: 'Brand - Marks shipment collected',
    // Referrals
    BRAND_OPENS_INVITE_FORM: "Brand - Clicks on 'invite retailer'",
    BRAND_SENDS_INVITE:
      "Brand - Clicks on 'close', 'cancel', 'send invite' from modal invite form",
    BRAND_INVITE_SENT: 'Brand - Status of sent invitation',
    BRAND_READS_REFERRALS_HIW: "Brand - Clicks on referrals 'how it works'",
    BRAND_READS_REFERRALS_TERMS:
      "Brand - Clicks on referrals 'terms and conditions'",
    BRAND_CLOSES_REFERRAL_BANNER:
      "Brand - Clicks 'close' on referral banner from referral page",
    BRAND_OPENS_REFERRAL_REMINDER_FORM: 'Brand opens referral reminder form',
    BRAND_CLOSES_REFERRAL_REMINDER_FORM: 'Brand closes referral reminder form',
    BRAND_SENDS_REFERRAL_REMINDER: 'Brand sends referral reminder',
    // Collection, Selection filter
    BRAND_APPLIES_FILTER_SLN: 'Brand - Applies filter selection',
    BRAND_APPLIES_FILTER_CLN: 'Brand - Applies filter collection',
    // Broadcast Mail for Brands
    BRAND_SEND_MESSAGE: 'Brand sent a message',
    BRAND_CANCELS_SENDING_MESSAGE:
      "Brand clicked 'cancel' after opening the message compose form",
    BRAND_VIEWS_SENT_MESSAGE: 'Brand opens already sent message',
    BRAND_SENDS_PERSONAL_INVITATION: 'Brand sends personal invitation',
    BRAND_ADDS_WALLET_RULE: 'Brand adds wallet rule',
    // Knowledge center tab
    BRAND_CLICKS_KNOWLEDGE_CENTER: 'Brand - Clicks Knowledge Center tab',

    BRAND_CLICKS_ON_ADD_TO_ORDER: "Brand - Clicks on 'add to order'",
    BRAND_SELECTS_RETAILER_FOR_ORDER: 'Brand - Selects retailer for order',
    BRAND_CLICKS_ON_PLACE_ORDER: "Brand - Clicks on 'place order'",
    BRAND_UPDATES_PRODUCT_IN_CART: 'Brand - Updates product in cart',
    BRAND_REMOVES_PRODUCT_FROM_CART: 'Brand - Removes product from cart',

    BRAND_INVITE_RETAILER_USING_WHATSAPP:
      'Brand - Invite retailer using whatsapp',

    BRAND_START_VIEWING_COLLECTION: 'Brand - Start viewing collection',
    BRAND_END_VIEWING_COLLECTION: 'Brand - End viewing collection',

    BRAND_START_VIEWING_SELECTION: 'Brand - Start viewing selection',
    BRAND_END_VIEWING_SELECTION: 'Brand - End viewing selection',

    BRAND_START_VIEWING_PRODUCT: 'Brand - Start viewing product details',
    BRAND_END_VIEWING_PRODUCT: 'Brand - End viewing product details',

    BRAND_OPENS_SHARE_LINK_POPUP: 'Brand opens share link popup',

    GET_SHARE_LINK_FAILURE: 'Get share link failure',
    GET_SHARE_LINK_SUCCESS: 'Get share link success',
    COPY_LINK_BUTTON_CLICKED: 'Copy link button clicked',

    // Updated on 08/10/2022
    // Retailer
    //
    RETAILER_DOWNLOADS_WISHLIST:
      "Retailer - Clicks on 'download' on wishlist download modal",
    RETAILER_MOVES_FROM_WISHLIST_TO_CART:
      'Retailer - Moves from wishlist to cart',
    RETAILER_REMOVES_PRODUCTS_FROM_WISHLIST:
      'Retailer - Removes products from wishlist',
    RETAILER_DELETES_WISHLIST:
      "Retailer - Clicks on 'delete wishlist' on wishlist delete modal",
    // Collection
    RETAILER_APPLIES_DELIVERY_DATES_FILTER:
      "Retailer - Apply filters' on filter by delivery date",
    RETAILER_GROUPS_PRODUCTS:
      'Retailer - Clicks on option on group products dropdown',
    RETAILER_SELECTS_GENERAL_VIEW: 'Retailer - Clicks on view button = general',
    RETAILER_SELECTS_COMPACT_VIEW:
      'Retailer - Clicks on view option button = compact',
    RETAILER_ADDS_TO_CART: 'Retailer - Adds to cart',
    RETAILER_ADDS_SELECTED_ITEMS_TO_CART:
      "Retailer - Selects items and clicks on actions -> 'move to cart'",
    RETAILER_ADDS_TO_WISHLIST:
      'Retailer - Clicks on heart icon which was not selected',
    RETAILER_MOVES_SELECTED_ITEMS_TO_WISHLIST:
      "Retailer - Selects items and clicks on actions -> 'move to wishlist'",
    RETAILER_REMOVES_FROM_WISHLIST:
      'Retailer - Clicks on heart icon which was selected',
    RETAILER_REMOVES_SELECTED_ITEMS_FROM_WISHLIST:
      "Retailer - Selects items and clicks on actions -> 'remove from wishlist'",
    // Cart
    RETAILER_EXPORTS_CART:
      "Retailer - Clicks on 'start export' on export cart modal",
    RETAILER_UPLOADS_CART: "Retailer - Clicks on 'upload' on upload cart modal",
    RETAILER_SAVES_CART: "Retailer - Clicks on 'save cart'",
    RETAILER_CLEARS_CART:
      "Retailer - Clicks on 'yes clear it' on clear cart modal window",
    RETAILER_PLACES_ORDER: "Retailer - Clicks on 'place order'",
    RETAILER_CHANGES_ADDRESS_IN_CART:
      "Retailer - Clicks on 'select another address'",
    RETAILER_ADDS_NEW_ADDRESS_IN_CART:
      "Retailer - Clicks on 'add a new shipping address'",
    // Order
    RETAILER_EXPORTS_ORDERS: "Retailer - Clicks on 'export orders'",
    RETAILER_DOWNLOADS_ORDER:
      "Retailer - Clicks on 'download' on order download modal",
    RETAILER_CHANGES_ORDER_STATUS: 'Retailer - Changes order status',
    // Shipment
    RETAILER_CREATES_SHIPMENT:
      "Retailer - Confirms creating shipment by clicking on 'create shipment' on shipment settings pop up",
    RETAILER_DOWNLOADS_INVOICE: 'Retailer - Downloads invoice',
    // Referrals
    RETAILER_OPENS_INVITE_FORM: "Retailer - Clicks on 'invite brand'",
    RETAILER_SENDS_INVITE:
      "Retailer - Clicks on 'close', 'cancel', 'send invite' from modal invite form",
    RETAILER_INVITE_SENT: 'Retailer - Status of sent invitation',
    RETAILER_READS_REFERRALS_HIW:
      "Retailer - Clicks on referrals 'how it works'",
    RETAILER_READS_REFERRALS_TERMS:
      "Retailer - Clicks on referrals 'terms and conditions'",
    RETAILER_CLOSES_REFERRAL_BANNER:
      "Retailer - Clicks 'close' on referral banner from referral page",
    RETAILER_CLOSES_REFERRAL_REMINDER_FORM:
      'Retailer closes referral reminder form',
    RETAILER_SENDS_REFERRAL_REMINDER: 'Retailer sends referral reminder',
    RETAILER_OPENS_REFERRAL_REMINDER_FORM:
      'Retailer opens referral reminder form',
    // Product card
    RETAILER_ADDS_TO_WISHLIST_FROM_PP:
      "Retailer - Clicks on 'Wishlist' button on product card",
    RETAILER_ADDS_TO_CART_FROM_PP:
      "Retailer - Clicks on 'add to cart' button on product card",
    // Collection, Wishlist filter
    RETAILER_APPLIES_FILTER_WLS: 'Retailer - Applies filter wishlist',
    RETAILER_APPLIES_FILTER_CLN: 'Retailer - Applies filter collection',
    RETAILER_SENDS_PERSONAL_INVITATION: 'Retailer sends personal invitation',
    // Explore
    RETAILER_CLICKS_DISCOVER_BRANDS: 'Retailer - Clicks discover brands',
    RETAILER_CLICKS_DISCOVER_PRODUCTS: 'Retailer - Clicks discover products',
    RETAILER_CLICKS_BOOK_A_MEETING: "Retailer - Clicks 'book a meeting' button",

    // Knowledge center tab
    RETAILER_CLICKS_KNOWLEDGE_CENTER: 'Retailer - Clicks Knowledge Center tab',

    RETAILER_START_VIEWING_COLLECTION: 'Retailer - Start viewing collection',
    RETAILER_END_VIEWING_COLLECTION: 'Retailer - End viewing collection',

    RETAILER_START_VIEWING_WISHLIST: 'Retailer - Start viewing wishlist',
    RETAILER_END_VIEWING_WISHLIST: 'Retailer - End viewing wishlist',

    RETAILER_START_VIEWING_PRODUCT: 'Retailer - Start viewing product',
    RETAILER_END_VIEWING_PRODUCT: 'Retailer - End viewing product',

    CART_WITH_MULTIPLE_STORES: {
      RETAILER_CLICKED_BUYING_SUMMARY_BUTTON:
        'Retailer - Clicks buying summary button(Cart page with multiple stores)',
      RETAILER_CLICKED_IMPORT_CART_BUTTON:
        'Retailer - Clicks import cart button(Cart page with multiple stores)',
      RETAILER_CLICKED_EXPORT_CART_BUTTON:
        'Retailer - Clicks export cart button(Cart page with multiple stores)',
      RETAILER_CLICKED_CLEAR_CART_BUTTON:
        'Retailer - Clicks clear cart button(Cart page with multiple stores)',
      RETAILER_CLICKED_SAVE_CART_BUTTON:
        'Retailer - Clicks save cart button(Cart page with multiple stores)',
      RETAILER_CLICKED_SELECT_STROES_AND_PLACE_ORDER_BUTTON:
        'Retailer - Clicks select stores and place order button(Cart page with multiple stores)',
      RETAILER_CLICKED_PLACE_ORDER_FOR_ALL_STORES_BUTTON:
        'Retailer - Clicks place order for all stores button(Cart page with multiple stores)',
      RETAILER_CLICKED_FILTER_STORES_DROPDOWN:
        'Retailer - Clicks filter stores dropdown(Cart page with multiple stores)',
      RETAILER_SELECT_ANOTHER_ADDRESS_BUTTON:
        'Retailer - Clicks select another address button(Cart page with multiple stores)',
      RETAILER_CLICKED_REMOVE_PRODUCT_BUTTON:
        'Retailer - Clicks remove product button(Cart page with multiple stores)',
      RETAILER_EXPANDS_CART:
        'Retailer - Expands cart(Cart page with multiple stores)',
      RERAILER_COLLAPSES_CART:
        'Retailer - Collapses cart(Cart page with multiple stores)',
      RETAILER_EXPANDS_ORDER_DETAILS_CARD:
        'Retailer - Expands order details card(Cart page with multiple stores)',
      RETAILER_COLLAPSES_ORDER_DETAILS_CARD:
        'Retailer - Collapses order details card(Cart page with multiple stores)',
      ORDER_PLACED_SUCCESSFULLY:
        'Order placed successfully(Cart page with multiple stores)',
      GETTING_ERROR_WHILE_PLACING_ORDER:
        'Getting error while retailer placing order(Cart page with multiple stores)',
      CART_SAVED_SUCCESSFULLY:
        'Cart saved successfully(Cart page with multiple stores)',
      GETTING_ERROR_WHILE_SAVING_CART:
        'Getting error while retailer saving cart(Cart page with multiple stores)',
      RETAILER_EXPORT_WISHLIST_AS_ORDER_FORM:
        'Retailer export wishlist as order form(Multiple stores)',
      RETAILER_EXPORT_CART_SUCCESSFULLY:
        'Retailer export cart successfully(Multiple stores)',
      RETAILER_EXPORT_CART_ERROR: 'Retailer export cart error(Multiple stores)',
      RETAILER_SEARCH_PRODUCTS_IN_CART:
        'Retailer search products in cart(Multiple stores)',
    },
    USER_CLICKS_KINGPIN_ACADEMY: 'User clicks on Kingpin Academy',
    USER_SEARCH_RETAILERS: 'User search retailers',
    USER_CLICKS_START_TOUR_ON_DASHBOARD: 'User clicks Start Tour on dashboard',
    USER_CLICKS_GO_TO_ORDER_BTN_ON_DASHBOARD:
      'User clicks Go to Order button on dashboard',
    USER_CLICKS_GO_TO_WISHLISTS_BTN_ON_DASHBOARD:
      'User clicks Go to Wishlists button on dashboard',
    USER_CLICKS_A_BRAND_ON_DASHBOARD: 'User clicks a brand on dashboard',
    USER_CLICKS_A_WISHLIST_ON_DASHBOARD: 'User clicks a wishlist on dashboard',
    USER_CLICKS_ONBOARDING_CHECKLIST_ITEM_ON_DASHBOARD:
      'User clicks onboarding checklist item on dashboard',

    BRAND_OPENS_SALES_INSIGHTS_TAB: 'Brand opens sales insights tab',
    BRAND_APPLIES_DATE_FILTER_IN_SALES_INSIGHTS_PAGE:
      'Brand applies date filter in sales insights page',
    BRAND_CLICKS_A_SELECTION_IN_SALES_INSIGHTS_PAGE:
      'Brand clicks a selection in sales insights page',
    BRAND_APPLIES_PAGINATION_IN_SALES_INSIGHTS_PAGE:
      'Brand applies pagination in sales insights page',
    BRNAD_CLICKS_CLICK_HERE_HYPERLINK_IN_SALES_INSIGHTS_PAGE:
      'Brand clicks "click here" hyperlink in sales insights page if the list is empty',

    BRAND_OPENS_SHARE_SELECTION_POPUP: 'Brand opens share selection popup',
    BRAND_SELECT_EMAIL_TO_SHARE_SELECTION:
      'Brand select retailer email to share selection',
    BRAND_CLICKS_SHARE_SELECTION_BTN: 'Brand clicks share selection button',
    SELCTION_SHARED_SUCCESSFULLY: 'Selection shared successfully',
    // share multiple selection events
    SHARE_MULTIPLE_SELECTIONS: {
      BRAND_SELECTS_SELECTION_BY_CHECKBOX: 'Brand selects a selection(checked)',
      BRAND_DESELECTS_SELECTION_BY_CHECKBOX:
        'Brand deselects the selection(unchecked)',
      BRNAD_CLICKS_SELECT_ALL_SELECTIONS:
        'Brand clicks "select all" selections from the "Other options" dropdown',
      BRAND_CLICKS_DESELECT_ALL_SELECTIONS:
        'Brand clicks "deselect all" selections from the "Other options" dropdown',
      BRAND_CLICKS_SHARE_SELECTION_TO_OPEN_SHARE_POPUP:
        'Brand clicks "share" button to open share selection popup',
      BRAND_CLOSES_THE_MULTI_SELECT_SELECTION_NAV_BAR:
        'Brand closes the multi select selection nav bar',
      BRAND_CLICKS_SHARE_SELECTION_BTN_ON_SHARE_POPUP:
        'Brand clicks "share" button on share selection popup',
      BRAND_CLICKS_CANCEL_BTN_ON_SHARE_POPUP:
        'Brand clicks "cancel" button on share selection popup',
      BRAND_SHARED_SELECTION_SUCCESSFULLY:
        'Brand shared selection successfully',
      BRAND_SHARED_SELECTION_FAILED: 'Brand shared selection failed',
      SELECTIONS_SHARING_RESULT_POPUP_OPENED:
        'Selections sharing result popup opened',
      BRAND_CLICKS_FOLLOW_UP_BUTTON: 'Brand clicks "follow up" button',
      FOLLOW_UP_SUCCESSED: 'Follow up successed',
      FOLLOW_UP_FAILED: 'Follow up failed',
      BRAND_CLICKS_DONT_FOLLOW_UP_BUTTON:
        'Brand clicks "Don\'t follow up" button',
      BRAND_CLOSES_FOLLOW_UP_POPUP: 'Brand closes follow up popup',
    },

    MASTER_PRICE_BOOK: {
      BRAND_CLICKS_EXPORT_MASTER_PRICE_BOOK:
        'Brand clicks master price book "export" button',
      BRAND_CLICKS_IMPORT_BTN_TO_OPEN_FILE_UPLOAD_POPUP:
        'Brand clicks "import" button to open file upload popup',
      BRAND_SELECTS_MASTER_PRICE_BOOK_FILE:
        'Brand selects master price book file',
      BRAND_CLICKS_IMPORT_BTN_ON_FILE_UPLOAD_POPUP:
        'Brand clicks "import" button on file upload popup',
      BRAND_CLICKS_BACK_BTN_ON_FILE_UPLOAD_POPUP:
        'Brand clicks "back" button on file upload popup',
      MASTER_PRICE_BOOK_EXPORTED_SUCCESSFULLY:
        'Master price book exported successfully',
      MASTER_PRICE_BOOK_EXPORT_FAILED: 'Master price book export failed',
      MASTER_PRICE_BOOK_IMPORTED_SUCCESSFULLY:
        'Master price book imported successfully',
      MASTER_PRICE_BOOK_IMPORT_FAILED: 'Master price book import failed',
    },
    ADD_STORE: {
      RETAILER_CLICKS_ADD_STORE_BTN: 'Retailer clicks "add store" button',
      RETAILER_CLICKS_ADD_BTN_ON_ADD_STORE_POPUP:
        'Retailer clicks "add" button on add store popup',
      RETAILER_CLICKS_CANCEL_BTN_ON_ADD_STORE_POPUP:
        'Retailer clicks "cancel" button on add store popup',
      RETAILER_STORE_ADDED_SUCCESSFULLY: 'Store added successfully',
      RETAILER_STORE_ADD_FAILED: 'Store add failed',
      RETAILER_ADDS_STORE_SUCCESSFULLY: 'Retailer adds store successfully',
    },
    MULTI_SELECT_PRODUCTS: {
      USER_SELECTS_PRODUCT_BY_CHECKBOX_IN_COLLECTION_PRODUCTS_PAGE:
        'User selects a product(checked) by checkbox in the collection products page',
      USER_DESELECTS_PRODUCT_BY_CHECKBOX_IN_COLLECTION_PRODUCTS_PAGE:
        'User deselects the product(unchecked) by checkbox in the collection products page',
      USER_SELECTS_PRODUCT_BY_CHECKBOX_IN_WISHLIST_PRODUCTS_PAGE:
        'User selects a product(checked) by checkbox in the wishlist products page',
      USER_DESELECTS_PRODUCT_BY_CHECKBOX_IN_WISHLIST_PRODUCTS_PAGE:
        'User deselects the product(unchecked) by checkbox in the wishlist products page',
      USER_CLICKS_SELECT_ALL_PRODUCTS_IN_COLLECTION_PRODUCTS_PAGE:
        'User clicks "select all" button in the collection products page',
      USER_CLICKS_DESELECT_ALL_PRODUCTS_IN_COLLECTION_PRODUCTS_PAGE:
        'User clicks "deselect all" button in the collection products page',
      USER_CLICKS_SELECT_ALL_PRODUCTS_IN_WISHLIST_PRODUCTS_PAGE:
        'User clicks "select all" button in the wishlist products page',
      USER_CLICKS_DESELECT_ALL_PRODUCTS_IN_WISHLIST_PRODUCTS_PAGE:
        'User clicks "deselect all" button in the wishlist products page',
      BOTTOM_TOOLBAR_OPENED: 'Bottom toolbar opened',
      BOTTOM_TOOLBAR_CLOSED: 'Bottom toolbar closed',
      BOTTOM_TOOLBAR_USER_CLICKS_SELECT_ALL:
        'User clicks "select all" button in the bottom toolbar',
      BOTTOM_TOOLBAR_USER_CLICKS_DESELECT_ALL:
        'User clicks "deselect all" button in the bottom toolbar',
      BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_SELECTION:
        'User clicks "add to selection" button in the bottom toolbar',
      BOTTOM_TOOLBAR_USER_CLICKS_REMOVE_FROM_SELECTION:
        'User clicks "remove from selection" button in the bottom toolbar',
      BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_WISHLIST:
        'User clicks "add to wishlist" button in the bottom toolbar',
      BOTTOM_TOOLBAR_USER_CLICKS_REMOVE_FROM_WISHLIST:
        'User clicks "remove from wishlist" button in the bottom toolbar',
      BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_CART:
        'User clicks "add to cart" button in the bottom toolbar',
      BOTTOM_TOOLBAR_USER_CLICKS_DOWNLOAD_PRODUCTS:
        'User clicks "download products" button in the bottom toolbar',
      BOTTOM_TOOLBAR_USER_CLICKS_ADD_TO_ORDER:
        'User clicks "add to order" button in the bottom toolbar',
    },
    PRODUCTS_PAGE_WISHLISTS: {
      // retailer events for wishlist
      RETAILER_OPENS_THE_WISHLISTS_DROPDOWN_BY_CLICKING_ON_THE_HEART_ICON:
        'Retailer opens the wishlists dropdown by clicking on the heart icon',
      RETAILER_CLOSES_THE_WISHLISTS_DROPDOWN:
        'Retailer closes the wishlists dropdown',
      RETAILER_CLICKS_CREATE_WISHLIST_IN_THE_WISHLISTS_DROPDOWN:
        'Retailer clicks a "+ create wishlist" in the wishlists dropdown',
      RETAILER_ADDED_PRODUCT_TO_A_WISHLIST_BY_CLICKING_A_MY_WISHLIST_IN_THE_WISHLISTS_DROPDOWN:
        'Retailer added product to a wishlist by clicking a wishlist in the wishlists dropdown(My wishlist section)', // dynamicaly did by the function onSelectWishlistOnProduct ProductWishlistsDropdown.vue
      RETAILER_REMOVED_PRODUCT_FROM_A_WISHLIST_BY_CLICKING_A_MY_WISHLIST_IN_THE_WISHLISTS_DROPDOWN:
        'Retailer removed product from a wishlist by clicking a wishlist in the wishlists dropdown(My wishlist section)', // dynamicaly did by the function onSelectWishlistOnProduct ProductWishlistsDropdown.vue
      RETAILER_ADDED_PRODUCT_TO_A_WISHLIST_BY_CLICKING_A_SHARED_WISHLIST_IN_THE_WISHLISTS_DROPDOWN:
        'Retailer added product to a wishlist by clicking a wishlist in the wishlists dropdown(Shared by brand section)', // dynamicaly did by the function onSelectWishlistOnProduct ProductWishlistsDropdown.vue
      RETAILER_REMOVED_PRODUCT_FROM_A_WISHLIST_BY_CLICKING_A_SHARED_WISHLIST_IN_THE_WISHLISTS_DROPDOWN:
        'Retailer removed product from a wishlist by clicking a wishlist in the wishlists dropdown(Shared by brand section)', // dynamicaly did by the function onSelectWishlistOnProduct ProductWishlistsDropdown.vue
      // brand events for selection
      BRAND_OPENS_THE_SELECTIONS_DROPDOWN_BY_CLICKING_ON_THE_HEART_ICON:
        'Brand opens the selections dropdown by clicking on the heart icon',
      BRAND_CLOSES_THE_SELECTIONS_DROPDOWN:
        'Brand closes the selections dropdown',
      BRAND_CLICKS_CREATE_SELECTION_IN_THE_SELECTIONS_DROPDOWN:
        'Brand clicks a "+ create selection" in the selections dropdown',
      BRAND_ADDED_PRODUCT_TO_A_SELECTION_BY_CLICKING_A_MY_SELECTION_IN_THE_SELECTIONS_DROPDOWN:
        'Brand added product to a selection by clicking a selection in the selections dropdown',
      BRAND_REMOVED_PRODUCT_FROM_A_SELECTION_BY_CLICKING_A_MY_SELECTION_IN_THE_SELECTIONS_DROPDOWN:
        'Brand removed product from a selection by clicking a selection in the selections dropdown',
    },
    LEAD_AI: {
      BRAND_CLICKS_CONTACT_BUTTON: 'Leads AI - Brand clicks contact button',
      BRAND_CLICKS_CONNECT_BUTTON: 'Leads AI - Brand clicks connect button',
      BRAND_SEARCH_LEADS: 'Leads AI - Brand search Leads',
      BRAND_CLICK_COUNTRY_LEADAI_FILTER: 'Leads AI - Brand apply country filter',
      BRAND_CLICK_LEADAI_FILTERS: 'Leads AI - Brand apply Leads AI filters',
      BRAND_CLICK_RESET_LEADAI_FILTERS: 'Leads AI - Brand reset Leads AI filters',
      BRAND_CLICKS_LEFT_ARROW:
        'Leads AI - Listing page - Brand clicked on left arrow',
      BRAND_CLICKS_RIGHT_ARROW:
        'Leads AI - Listing page - Brand clicked on right arrow',
      BRAND_CLICKS_BACK_BUTTON:
        'Leads AI - Detail page  - Brand clicked on back button',
    },
    SIGN_IN: {
      USER_CLICKS_CREATE_ACCOUNT_LINK: 'Sign in - User clicks create account link',
    },
    SIGN_UP: {
      USER_SELECTS_BRAND_AS_ROLE: 'Sign up - User selects brand as role',
      USER_SELECTS_RETAILER_AS_ROLE: 'Sign up - User selects retailer as role',
      USER_TYPES_BRAND_NAME: 'Sign up - User types brand name',
      USER_TYPES_RETAILER_NAME: 'Sign up - User types retailer name',
      USER_SUBMITS_BRAND_NAME: 'Sign up - User clicks next step (Brand)',
      USER_SUBMITS_RETAILER_NAME: 'Sign up - User clicks next step (Retailer)',
      USER_ENTERS_PERSONAL_DETAILS: 'Sign up - User enters personal details',
      USER_SUBMITS_PERSONAL_DETAILS: 'Sign up - User clicks next step (Personal Details)',
      USER_ENTERS_CREDENTIALS: 'Sign up - User enters credentials',
      USER_CLICKS_AGREE_TO_TERMS: 'Sign up - User clicks agree to terms check',
      USER_CLICKS_PRIVACY_LINK: 'Sign up - User clicks privacy policy link',
      USER_CLICKS_TERMS_LINK: 'Sign up - User clicks terms and conditions link',
      USER_CLICKS_CREATE_ACCOUNT_BUTTON: 'Sign up - User clicks create account button',
    },
  },
}
