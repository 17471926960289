<template>
  <div class="no-leads-found">
    <h2 class="details-empty-title">
      {{ title }}
    </h2>
    <p class="details-empty-text">
      {{ topDescription }}
    </p>
    <p class="details-empty-text">
      {{ bottomDescription }}
    </p>
    <b-button
      ref="noopener noreferrer"
      class="background-highlight details-empty-btn"
      variant="primary"
      :href="contactLink"
      target="_blank"
    >
      {{ buttonText }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
    name: 'LeadAIError',
    components: {
        BButton,
    },
    props: {
        contactLink: {
            type: String,
            default: 'https://calendar.app.google/XgMuYFpJUtaXxHqe7',
        },

        title: {
            type: String,
            default: "Don't see the leads you desire?",
        },
        topDescription: {
            type: String,
            default: "Kingpin's AI is being trained before public launch in January 2025.",
        },
        bottomDescription: {
            type: String,
            default: 'Book a call with our Customer Success team to discover how we can source targeted prospects tailored for your business!',
        },
        buttonText: {
            type: String,
            default: 'Get more tailored leads',
        },
    },
}

</script>

<style scoped>
.no-leads-found {
  max-width: 645px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.details-empty-title {
  color: #8d82fd;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Manrope;
  font-size: 35.007px;
  font-style: normal;
  font-weight: 700;
  line-height: 42.008px; /* 120% */
}
.details-empty-text {
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Manrope;
  font-size: 21.004px;
  font-style: normal;
  font-weight: 400;
  line-height: 30.631px; /* 145.833% */
  text-align: left;
}
.background-highlight {
  background-color: #ede1f5 !important;
}

.details-empty-btn {
  color: #292929 !important;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Manrope;
  font-size: 16.483px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.471px;
  border: none !important;
  align-self: flex-start;
}
</style>
