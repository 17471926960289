<template>
  <validation-observer
    ref="tailoredLeads"
    #default="{ validateWithInfo }"
  >
    <form @submit.prevent>
      <div class="wrapper">
        <p class="section-title">
          Tell us about you
        </p>
        <b-row class="px-1">
          <b-col
            md="6"
            class="left-col"
          >
            <validation-provider
              #default="{ errors }"
              name="Website"
              rules="required|url"
            >
              <b-form-group
                class="custom-form-group-styles"
                label="Website *"
                label-class="w-fit-content custom-label-style"
              >
                <b-form-input
                  v-model="website"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Your website url like www.example.com"
                  @keydown.space.prevent
                  @paste="removeSpaces"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
            class="right-col"
          >
            <b-form-group class="position-up">
              <label class="custom-label-style">Average retail price, $</label>
              <custom-select
                id="avg-retail-price-dropdown"
                v-model="avgRetailPrices"
                :searchable="false"
                multiple
                show-arrow-icon
                placeholder="e.g. 50-100, 100-200"
                no-data-text="No price range found."
                theme="grey"
                :option-groups="avgRetailPricesOptions"
                size="small"
                @search="handleSearchRetailPrices"
                @select="(prices) => avgRetailPrices = prices"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="px-1 pb-1">
          <b-col
            md="6"
            class="left-col"
          >
            <b-form-group
              class="custom-form-group-styles"
              label="Target end customer profile"
              label-class="w-fit-content custom-label-style"
            >
              <b-form-textarea
                v-model="targetEndCustomerProfile"
                type="textarea"
                rows="4"
                max-rows="4"
                no-resize
                placeholder="Who are your products designed for? Include demographics like age, gender, income level, and interests (e.g., men, 18-24 years old, athletes, middle-income)"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="right-col"
          >
            <b-form-group
              class="custom-form-group-styles"
              label="Partnership objectives"
              label-class="w-fit-content custom-label-style"
            >
              <b-form-textarea
                v-model="partnershipObjectives"
                type="textarea"
                rows="4"
                max-rows="4"
                no-resize
                placeholder="What are your business objectives for collaboration with desired leads? (e.g., strengthening brand positioning, accessing new customer segments, or scaling distribution channels)."
              />
            </b-form-group>
          </b-col>
        </b-row>
        <p class="section-title">
          Tell us about your ideal leads
        </p>
        <b-row class="px-1">
          <b-col
            class="left-col"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              name="Lookalike leads names"
              rules="required"
            >
              <b-form-group
                class="tag-form-group"
                label-class="w-fit-content custom-label-style"
              >
                <template #label>
                  Lookalike leads names * <span class="grey-label-text">(comma separated words, 10 max)</span>
                </template>
                <b-form-tags
                  v-model="lookalikeLeads"
                  separator=","
                  tag-class="custom-tag"
                  remove-on-delete
                  :state="errors.length > 0 ? false : null"
                  duplicate-tag-text="Duplicate lead name"
                  :limit="10"
                  limit-tags-text=""
                  :placeholder="!lookalikeLeads.length ? `Share the names of businesses that are similar to your target leads` : ''"
                  input-id="lookalikeLeadInput"
                  @input="(val) =>handleFormTagLimit(val, 'lookalikeLeadInput')"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
            class="right-col"
          >
            <b-form-group
              class="tag-form-group"
              label-class="w-fit-content custom-label-style"
            >
              <template #label>
                Desired industries <span class="grey-label-text">(comma separated words)</span>
              </template>
              <b-form-tags
                v-model="desiredIndustries"
                separator=","
                tag-class="custom-tag"
                remove-on-delete
                duplicate-tag-text="Duplicate industry name"
                :placeholder="!desiredIndustries.length ? `Specify the industries you want to target for your leads` : ''"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="px-1">
          <b-col
            md="6"
            class="left-col"
          >
            <b-form-group
              class="tag-form-group"
              label-class="w-fit-content custom-label-style"
            >
              <template #label>
                Desired keywords describing the leads <span class="grey-label-text">(comma separated words, 10 max)</span>
              </template>
              <b-form-tags
                v-model="desiredKeywords"
                separator=","
                tag-class="custom-tag"
                :limit="10"
                limit-tags-text=""
                remove-on-delete
                duplicate-tag-text="Duplicate keyword"
                :placeholder="!desiredKeywords.length ? `List specific terms that define your target leads' attributes or focus` : ''"
                input-id="desiredKeywordsInput"
                @input="(val) =>handleFormTagLimit(val, 'desiredKeywordsInput')"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="right-col"
          >
            <b-form-group
              class="tag-form-group"
              label-class="w-fit-content custom-label-style"
            >
              <template #label>
                Keywords to exclude from the leads description <span class="grey-label-text">(comma separated words, 10 max)</span>
              </template>
              <b-form-tags
                v-model="excludeKeywords"
                separator=","
                tag-class="custom-tag"
                remove-on-delete
                duplicate-tag-text="Duplicate keyword"
                :placeholder="!excludeKeywords.length ? `Specify terms to exclude from the description of your target leads` : ''"
                :limit="10"
                limit-tags-text=""
                input-id="excludeKeywordInput"
                @input="(val) =>handleFormTagLimit(val, 'excludeKeywordInput')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="px-1">
          <b-col
            md="6"
            class="left-col"
          >
            <b-form-group class="position-up">
              <label class="custom-label-style">Desired countries</label>
              <custom-select
                id="desired-countries-dropdown"
                v-model="desiredCountries"
                multiple
                show-arrow-icon
                placeholder="Enter the countries where you want to focus your lead search"
                no-data-text="No country found."
                theme="grey"
                size="small"
                :option-groups="desiredCountriesOptions"
                @search="handleSearchCountries"
                @select="(countries) => desiredCountries = countries"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="right-col"
          >
            <b-form-group
              class="custom-form-group-styles"
              label="Additional comments"
              label-class="w-fit-content custom-label-style"
            >
              <b-form-input
                v-model="additionalComments"
                placeholder="Add any comments or notes that can help AI tailor the results to your needs"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          class="w-100 d-flex"
          :class="errorMsg ? `justify-content-between` : `justify-content-end`"
        >
          <p
            v-if="errorMsg"
            class="error-text"
          >
            {{ errorMsg }}
          </p>
          <b-button
            :disabled="isFormLoading"
            variant="light"
            class="submit-btn"
            @click.prevent="handleFormSubmit(validateWithInfo)"
          >
            <b-spinner
              v-if="isFormLoading"
              class="mr-1"
              small
              label="Loading..."
            />
            <span v-if="isFormLoading">Loading...</span>
            <span v-else>Get tailored leads</span>
          </b-button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import {
 BFormInput, BFormGroup, BCol, BRow, BFormTextarea, BButton, BFormTags, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import CustomSelect from '@/@core/components/custom-select/CustomSelect.vue'
import store from '@/store'
import { GET_COUNTRIES } from '@/store/modules/common.module'

export default {
  name: 'TailoredLeadsForm',
  components: {
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    CustomSelect,
    BButton,
    BFormTags,
    BSpinner,
  },
  data() {
    return {
      website: '',
      avgRetailPrices: [],
      avgRetailPricesOptions: [],
      targetEndCustomerProfile: '',
      partnershipObjectives: '',
      desiredCountries: [],
      desiredKeywords: [],
      lookalikeLeads: [],
      desiredIndustries: [],
      excludeKeywords: [],
      additionalComments: '',
      desiredCountriesOptions: [],
      allAvgRetailPrices: [
        'Under 50',
        '51 - 100',
        '101 - 150',
        '151 - 200',
        '250 - 300',
        '> 300',
      ],
      allCountries: [],
      isFormLoading: false,
      errorMsg: '',
    }
  },
  created() {
    this.getAllCountries()
    this.avgRetailPricesOptions = [
      {
        title: '',
        options: this.allAvgRetailPrices,
      },
    ]
  },
  mounted() {
    const label = document.getElementsByClassName('desired-country-label')
    if (label?.length) {
      label[0].addEventListener('click', e => e.preventDefault())
    }
  },
  methods: {
    allowOnlyBackspace(e) {
      if (e.key !== 'Backspace') {
        e.preventDefault()
      }
    },
    handleFormTagLimit(val, inputId) {
      const inputEl = document.getElementById(inputId)
      if (!inputEl) {
        return
      }
      if (val.length >= 10) {
        inputEl.addEventListener('keydown', this.allowOnlyBackspace)
      } else {
        inputEl.removeEventListener('keydown', this.allowOnlyBackspace)
        inputEl.focus()
      }
    },
    getAllCountries() {
      store.dispatch(GET_COUNTRIES).then(res => {
        this.allCountries = res.data.data.COUNTRIES
        this.desiredCountriesOptions = [{
          title: '',
          options: this.allCountries,
        }]
      })
    },
    removeSpaces(e) {
      e.preventDefault()
      const left = e.target.value.substring(0, e.target.selectionStart)
      const right = e.target.value.substring(e.target.selectionEnd, e.target.value.length)
      const pasted = (e.dataTransfer || e.clipboardData).getData('text').replace(/ /g, '')
      e.target.value = left + pasted + right
    },
    handleSearchRetailPrices(searchString) {
      this.avgRetailPricesOptions = [
        {
          title: '',
          options: this.allAvgRetailPrices.filter(range => range.toLowerCase()
          .indexOf(searchString.toLowerCase()) !== -1),
        },
      ]
    },
    handleSearchCountries(searchString) {
      this.desiredCountriesOptions = [
        {
          title: '',
          options: this.allCountries.filter(country => country.toLowerCase()
          .indexOf(searchString.toLowerCase()) !== -1),
        },
      ]
    },
    async handleFormSubmit(validate) {
      const validationData = await validate()
      this.getErrors(validationData)
      if (validationData?.isValid) {
          this.isFormLoading = true

        setTimeout(() => {
          this.isFormLoading = false
        }, 2000)
      }
    },
    getErrors(data) {
      const websiteErrors = data.fields?.Website?.failedRules
      const lookalikeLeadsErrors = data.fields['Lookalike leads names']?.failedRules
      const websiteRequired = websiteErrors?.required
      const leadsRequired = lookalikeLeadsErrors?.required
      const invalidUrl = websiteErrors?.url
      this.updateErrorMsg({
        websiteRequired,
        leadsRequired,
        invalidUrl,
      })
    },
    updateErrorMsg(errors) {
      const { websiteRequired, leadsRequired, invalidUrl } = errors

      if (websiteRequired && leadsRequired) {
        this.errorMsg = 'Please fill in your website url and “Lookalike leads names” field.'
      } else if (websiteRequired) {
        this.errorMsg = 'Please fill in your website url.'
      } else if (invalidUrl && leadsRequired) {
        this.errorMsg = 'Please enter valid website url and fill “Lookalike leads names” field.'
      } else if (leadsRequired) {
        this.errorMsg = 'Please fill “Lookalike leads names” field.'
      } else if (invalidUrl) {
        this.errorMsg = 'Please enter valid website url.'
      } else this.errorMsg = ''
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes

.wrapper {
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  padding: 16px 24px;
  padding-top: 26px;
  max-width: 70.4vw;

  .error-text {
    color: #D60909;
    font-weight: 500;
    max-width: 500px;
  }

  .left-col {
    padding: 0;
    padding-right: 1rem;
  }

  .right-col {
    padding: 0;
    padding-left: 1rem;
  }

  .section-title {
    font-size: 16px;
    font-weight: bold;
  }

  .custom-label-style {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 3px;
    margin-bottom: 0;
  }

  .w-fit-content {
    width: fit-content !important;
  }

  .custom-form-group-styles {
    input {
      font-size: 12px;
      padding: 10px 9px !important
    }

    textarea {
      font-size: 12px;
      padding: 9px 8px !important
    }
  }

  input:placeholder-shown {
    text-overflow: ellipsis;
  }

  .position-up {
    position: relative;
    top: -2px
  }

  .tag-form-group {
    .form-control {
      padding: 10px 9px;
    }
    input {
      font-size: 12px;
    }

    .grey-label-text {
      width: fit-content;
      display: inline;
      font-size: inherit;
      color: $lighter-gray;
    }
    .form-control {
      padding-top: 5px;
      padding-bottom: 5px;

      .b-form-tags-input {
        min-height: 26px;
      }

      .b-form-tags-button {
        display: none;
      }
    }
    .custom-tag {
      color: $primary !important;
      font-weight: 500;
      font-size: 12px;
      display: flex !important;
      justify-content: space-between;
      background-color: $bright-gray !important;
      border-radius: 3px;
      align-items: center !important;
      margin-right: 7px;

      button.b-form-tag-remove {
        align-self: end;
      }
    }
  }

  .submit-btn {
    background-color: $custom-secondary !important;
    color: $primary !important;
    font-weight: 400;
    width: 234px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .wrapper {
    .left-col, .right-col {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

</style>
