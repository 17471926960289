<template>
  <div>
    <b-card title="No Products Added">
      <b-card-text>
        Go to "Explore" and start adding some products to your Amazing Cart
      </b-card-text>
      <div class="d-flex">
        <div id="explore_button">
          <b-button
            :to="{ name: 'explore' }"
            variant="primary"
            :disabled="!isEnabledMarketplace"
          >
            Explore Now
          </b-button>
        </div>
        <b-button
          v-b-modal.import_from_excel_cart
          class="ml-1"
          variant="primary"
        >
          Import from Excel
        </b-button>
      </div>
      <!-- Import button appears if there is no cart products -->
    </b-card>
    <b-tooltip
      v-if="!isEnabledMarketplace"
      target="explore_button"
      placement="bottom"
    >
      {{ marketplaceAccessDeniedMessage }}
    </b-tooltip>
  </div>
</template>

<script>
import { marketplaceAccessDeniedMessage } from '@/constants'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { mapState } from 'vuex'
import {
 BButton, BCard, BCardText, BTooltip, VBModal,
} from 'bootstrap-vue'

const { BRAND_ACCESS } = c

export default {
    name: 'ExploreCollection',
    components: {
        BCard,
        BCardText,
        BButton,
        BTooltip,
    },
    directives: {
        'b-modal': VBModal,
    },
    data() {
        return {
            marketplaceAccessDeniedMessage,
        }
    },
    computed: {
        ...mapState({
            profile: state => state.auth.profileData,
        }),
        isEnabledMarketplace() {
            return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
        },
    },
}
</script>
