<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4088 10.2849L14.3937 13.2141C14.659 13.4745 14.659 13.8651 14.3937 14.1255C14.261 14.2557 14.062 14.3207 13.9294 14.3207C13.7967 14.3207 13.5977 14.2557 13.4651 14.1255L10.4802 11.1962C9.48525 11.9123 8.2913 12.3679 6.96469 12.3679C3.84716 12.3679 1.3266 9.89433 1.3266 6.8349C1.3266 3.77547 3.84716 1.30188 6.96469 1.30188C10.0822 1.30188 12.6028 3.77547 12.6028 6.8349C12.6028 8.13679 12.1385 9.37358 11.4088 10.2849ZM6.96469 2.60377C4.57679 2.60377 2.65321 4.4915 2.65321 6.8349C2.65321 9.1783 4.57679 11.066 6.96469 11.066C8.15864 11.066 9.21992 10.6104 10.0159 9.82924C10.8119 9.04811 11.2762 8.0066 11.2762 6.8349C11.2762 4.4915 9.35259 2.60377 6.96469 2.60377Z"
      fill="black"
    />
    <mask
      id="mask0_2070_1650"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4088 10.2849L14.3937 13.2141C14.659 13.4745 14.659 13.8651 14.3937 14.1255C14.261 14.2557 14.062 14.3207 13.9294 14.3207C13.7967 14.3207 13.5977 14.2557 13.4651 14.1255L10.4802 11.1962C9.48525 11.9123 8.2913 12.3679 6.96469 12.3679C3.84716 12.3679 1.3266 9.89433 1.3266 6.8349C1.3266 3.77547 3.84716 1.30188 6.96469 1.30188C10.0822 1.30188 12.6028 3.77547 12.6028 6.8349C12.6028 8.13679 12.1385 9.37358 11.4088 10.2849ZM6.96469 2.60377C4.57679 2.60377 2.65321 4.4915 2.65321 6.8349C2.65321 9.1783 4.57679 11.066 6.96469 11.066C8.15864 11.066 9.21992 10.6104 10.0159 9.82924C10.8119 9.04811 11.2762 8.0066 11.2762 6.8349C11.2762 4.4915 9.35259 2.60377 6.96469 2.60377Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2070_1650)">
      <rect
        width="15.9193"
        height="15.6226"
        fill="#757575"
      />
    </g>
  </svg>
</template>
<script>
export default {
    name: 'SearchIcon',
}
</script>
