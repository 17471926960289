<template>
  <b-modal
    :id="modalId"
    centered
    hide-header
    hide-footer
    @hidden="resetProperties"
  >
    <div v-if="!isEnableCreateWishlist && !!wishlists.length">
      <div
        class="
          pb-5-px
          border-bottom
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="elements-center">
          <span class="custom-modal-heading"> My {{ getLabelText }}s </span>
        </div>
        <feather-icon
          id="close-wishlist-modal"
          class="float-right color-neutral-black cursor-pointer"
          icon="XIcon"
          size="24"
          @click="hideModal"
        />
      </div>
      <div class="position-relative col-md-12 p-2">
        <b-form-input
          v-model="search"
          :placeholder="'Search ' + getLabelText"
          class="search-product"
        />
        <feather-icon
          icon="SearchIcon"
          size="18"
          class="wallet-search-icon"
        />
      </div>
      <div class="wishlist-list">
        <div
          class="item"
          :class="{ 'bg-secondary text-white': selectedWishlist && selectedWishlist._id === '' }"
          @click="onUnsetWishlistSelection"
        >
          <div class="cart-item-title pl-1">
            None
          </div>
        </div>
        <h4
          v-if="filteredMyWishlists.length"
          class="mt-1"
        >
          My Wishlists:
        </h4>
        <div
          v-for="wishlist in filteredMyWishlists"
          :key="wishlist._id"
          class="item"
          :class="{ 'bg-secondary text-white': selectedWishlist && selectedWishlist._id === wishlist._id }"
          @click="onSelectWishlist(wishlist)"
        >
          <div class="cart-item-title pl-1">
            {{ wishlist.name }}
          </div>
        </div>
        <h4
          v-if="filteredBrandWishlists.length && !isBrand"
          class="mt-1"
        >
          Shared by Brand:
        </h4>
        <div
          v-for="wishlist in filteredBrandWishlists"
          :key="wishlist._id"
          class="item"
          :class="{ 'bg-secondary text-white': selectedWishlist && selectedWishlist._id === wishlist._id }"
          @click="onSelectWishlist(wishlist)"
        >
          <div class="cart-item-title pl-1">
            {{ wishlist.name }}
          </div>
        </div>
        <div
          v-if="!filteredMyWishlists.length && !filteredBrandWishlists.length"
          class="text-muted p-2"
        >
          Wishlists not found
        </div>
      </div>
      <div
        v-if="!isCreateWishlistBtnDisabled"
        class="my-75"
      >
        <b-button
          variant="success"
          block
          @click="addNewWishlist"
        >
          Add new {{ getLabelText.toLowerCase() }}
        </b-button>
      </div>
    </div>
    <div v-else>
      <div
        class="
          pb-5-px
          border-bottom
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="elements-center">
          <span class="custom-modal-heading"> Enter {{ getLabelText }} Name </span>
        </div>
        <feather-icon
          class="float-right color-neutral-black cursor-pointer"
          icon="XIcon"
          size="24"
          @click="closeModal()"
        />
      </div>
      <div class="wishlist-list">
        <b-form-group class="mt-2">
          <template #label>
            {{ getLabelText }} name
          </template>
          <b-form-input
            v-model="selectionName"
            :placeholder="`Enter ${getLabelText.toLowerCase()} name (optional)`"
          />
        </b-form-group>
      </div>
      <div class="my-75">
        <b-button
          variant="success"
          block
          @click="createSelectionWithName"
        >
          Create
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import { apiToastSuccess } from '@core/utils/toast'
import { mapState } from 'vuex'
import { SET_IS_ENABLE_CREATE_WISHLIST } from '@/store/modules/wishlist.module'
import constants from '@/constants'
import analytics from '@/@core/utils/analytics'

const { TRACKS: { ACTIONS } } = constants

export default {
  components: {
    BModal,
    FeatherIcon,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin],
  props: {
    wishlists: {
      type: Array,
      default() {
        return []
      },
    },
    selectedWishlist: {
      type: Object,
      default: () => { },
    },
    modalId: {
      type: String,
      default: 'wishlist-list',
    },
  },
  data() {
    return {
      selectionName: '',
      selectedWishlistData: {},
      search: '',
      SET_IS_ENABLE_CREATE_WISHLIST,
    }
  },
  emits: ['move-to-wishlist', 'unset-wishlist-selection'],
  computed: {
    getLabelText() {
      return this.isRetailer ? 'Wishlist' : 'Selection'
    },
    filteredMyWishlists() {
      const searchTerm = this.search?.trim().toLowerCase()
      return this.wishlists.filter(wishlist => !wishlist?.isBrandInitiated && wishlist.name?.toLowerCase().includes(searchTerm))
    },
    filteredBrandWishlists() {
      const searchTerm = this.search?.trim().toLowerCase()
      return this.wishlists.filter(wishlist => wishlist?.isBrandInitiated && wishlist.name?.toLowerCase().includes(searchTerm))
    },
    ...mapState({
      isEnableCreateWishlist: state => state.wishlist.isEnableCreateWishlist,
      isCreateWishlistBtnDisabled: state => state.wishlist.isCreateWishlistBtnDisabled,
    }),
  },
  methods: {
    addNewWishlist() {
      this.$store.commit(SET_IS_ENABLE_CREATE_WISHLIST, true)
    },
    onUnsetWishlistSelection() {
      if (this.isRetailer && this.selectedWishlist._id !== '') {
        this.$emit('unset-wishlist-selection', { wishlistId: '' })
      }
      this.closeModal()
    },
    onSelectWishlist(wishlist = {}) {
      this.closeModal()
      if (this.isCreateWishlistBtnDisabled) {
        this.selectedWishlistData._id !== wishlist?._id && apiToastSuccess(`${this.getLabelText} "${wishlist?.name}" selected`)
      } else {
        apiToastSuccess(`Products added to the ${this.getLabelText?.toLowerCase()} "${wishlist?.name}"`)
      }
      this.$emit('move-to-wishlist', { wishlistId: wishlist?._id || null, wishlist: wishlist ?? this.selectedWishlistData })
      this.selectedWishlistData = wishlist
    },
    createSelectionWithName() {
      const emitData = { wishlistName: this.selectionName, wishlist: this.selectedWishlistData, isCreateWishlist: true }
      this.closeModal()
      this.$emit('should-change-wishlist')
      this.$emit('move-to-wishlist', emitData)
    },
    closeModal() {
      this.resetProperties()
      this.$bvModal.hide(this.modalId)
    },
    resetProperties() {
      if (!this.isEnableCreateWishlist) {
        setTimeout(() => this.$emit('should-change-wishlist'), 200)
      }
      this.$store.commit(SET_IS_ENABLE_CREATE_WISHLIST, false)
      this.selectionName = ''
      this.search = ''
    },
    hideModal() {
      if (this.isBrand) {
        analytics.track(ACTIONS.BRAND_CLOSES_CREATE_SELECTION_POPUP_WITHOUT_ACTION)
      }

      this.$bvModal.hide(this.modalId)
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';

.wishlist-list {
  max-height: 500px;
  overflow-y: auto;

  .item {
    padding: 0.9rem 0;
    border: none;
    border-bottom: 1px solid $border-color;
    cursor: pointer;

    &:hover {
      background: $body-bg;
    }
  }
}

.search-product {
  width: 100%;
  padding-right: 2.5rem;
  box-sizing: border-box;
}

.wallet-search-icon {
  position: absolute;
  top: 50%;
  right: 2.2rem;
  transform: translateY(-50%);
  pointer-events: none;
}

.text-muted {
  color: #6c757d;
}
</style>
