<template>
  <b-card
    class="lead-card ecommerce-card ecom overflow-visible"
    no-body
  >
    <div class="card-square-image">
      <img
        :src="imageUrl"
        alt="Logo"
        class="img img-responsive img-fluid"
        @error="imageError"
      >
    </div>
    <b-card-body class="d-flex flex-column custom-padding">
      <div class="flex-grow-1">
        <p
          :title="lead.storeName"
          class="ellipsis-text two-lines custom-font-size"
        >
          {{ lead.storeName }}
        </p>
        <p
          :title="lead.category"
          class="ellipsis-text two-lines mt-half custom-font-size"
        >
          {{ lead.category }}
        </p>
        <hr class="lead-divider">
        <b-card-text class="lead-location mt-half ellipsis-text two-lines">
          <feather-icon
            icon="MapPinIcon"
            size="12"
          />
          <span class="country-text custom-font-size"> {{ displayLocation(lead) }}</span>
        </b-card-text>
        <div class="lead-insights">
          <div class="lead-insights-title mt-1">
            Leads Insights
          </div>
          <b-card-text class="text mb-0 custom-font-size ellipsis-text three-lines">
            <strong class="mt-1">Store Type:</strong>
            {{ lead.storeTypes.join(', ') || 'N/A' }}
          </b-card-text>
          <b-card-text class="text mt-1 mb-1 custom-font-size ellipsis-text three-lines">
            <strong>AVG Retail Price:</strong>
            {{ lead.averageRetailPrice.join(', ') || 'N/A' }}
          </b-card-text>
        </div>
      </div>
      <div class="button-container">
        <b-button
          :disabled="isLoading"
          variant="primary"
          class="details-btn d-flex align-items-center justify-content-center w-100 "
          @click="handleViewDetails"
        >
          <b-spinner
            v-if="isLoading"
            class="mr-1"
            small
            label="Loading..."
          />
          <span v-if="isLoading">Loading...</span>
          <span v-else>See details and contacts</span>
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
 BButton, BCard, BCardBody, BCardText, BSpinner,
} from 'bootstrap-vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { getDefaultProductImage } from '@/@core/utils/utils'

export default {
  name: 'LeadAICard',
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BSpinner,
  },
  props: {
    lead: {
      type: Object,
      default: () => ({
        storeName: '',
        category: '',
        country: '',
        locality: '',
        storeTypes: [],
        averageRetailPrice: [],
      }),
      required: true,
    },
    onViewDetails: {
      type: Function,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      getDefaultProductImage,
      imageUrl: '',
      isLoading: false, // State to track loading
    }
  },
  beforeMount() {
    this.imageUrl = getDefaultProductImage(300, 250)
    if (this.lead.smallLogoUrl) {
      const image = new Image()
      image.onload = () => {
        if (image.width > 75) this.imageUrl = this.lead.smallLogoUrl
      }
      image.src = this.lead.smallLogoUrl
    }
  },
  methods: {
    async handleViewDetails() {
      this.isLoading = true // Start loading
      try {
        analytics.track(
          constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_CONTACT_BUTTON,
          {
            leadId: this.lead.id,
            lead_storeName: this.lead.storeName,
          },
        )
        // Wait for the parent to process the emitted event
        await this.onViewDetails(this.lead.id)
      } catch (error) {
        console.error('Error while viewing details:', error)
      } finally {
        this.isLoading = false // Stop loading
      }
    },
    displayLocation(leadDetails) {
      if (!leadDetails.country) {
        return 'N/A'
      }
      if (!leadDetails.locality) {
        return leadDetails.country
      }
      return `${leadDetails.country} - ${leadDetails.locality}`
    },
    imageError(e) {
      e.target.src = getDefaultProductImage(300, 250)
    },
  },
}
</script>
<style lang="scss">
.lead-card {
  min-height: 560px;
}
.custom-padding {
  padding: 16px;
}
.details-btn {
  padding: 0.786rem;
}
.custom-font-size {
  font-size: 12px;
  line-height: normal;
}

.card-square-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 232px;
  max-height: 232px;

  img {
    display: block;
    max-width: 100%;
    max-height: 232px;
  }
}

.mt-half {
  margin-top: 0.5rem
}

.ellipsis-text {
  max-width: calc(100% - 1px);
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.two-lines {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.three-lines {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

@media (max-width: 1440px) {
  .custom-padding {
    padding: 16px;
  }
  .details-btn {
    font-size: 11px;
    padding: 0.786rem 0.25rem;
  }
}
</style>
