<template>
  <b-tabs
    v-model="tabIndex"
    class="tablist"
    nav-wrapper-class="tablist-nav-wrapper"
    @input="handleTabChange"
  >
    <b-tab title="LEADS AI">
      <div class="w-100 h-100 flex align-center justify-center">
        <leads-ai-tab />
      </div>
    </b-tab>
    <b-tab title="VIEWED LEADS">
      <div class="w-100 h-100 flex align-center justify-center">
        <h3>Viewed leads section will appear here</h3>
      </div>
    </b-tab>
    <b-tab title="LEADS DATABASE">
      <p class="tab-description">
        <span>"Leads Database"</span> allows you to explore our extensive collection of leads. Use filters and search
        options to navigate and discover potential matches tailored to your business needs.
      </p>
      <leads-database />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { onBeforeUnmount } from '@vue/composition-api'
import LeadsDatabase from './tabs/LeadsDatabase.vue'
import LeadsAiTab from './tabs/LeadsAiTab/LeadsAiTab.vue'

export default {
  components: {
    LeadsDatabase,
    LeadsAiTab,
    BTabs,
    BTab,
  },
  data() {
    return {
      tabIndex: 0,
      tabData: [
        {
          id: 'leads-ai',
          name: 'Leads AI',
          default: true,
        },
        {
          id: 'viewed-leads',
          name: 'Viewed Leads',
        },
        {
          id: 'leads-database',
          name: 'Leads Database',
        },
      ],
    }
  },
  created() {
    const activeTab = this.$route.query?.tab
    this.updateActiveTab(activeTab)

    this.$watch(() => this.$route.query.tab, newTab => {
      this.updateActiveTab(newTab)
    })
  },
  setup() {
    const startTime = Date.now()

    onBeforeUnmount(() => {
      analytics.track(constants.TRACKS.PAGES.BRAND_OPENS_LEAD_AI_LIST, {
        timeSpent: `${(Date.now() - startTime) / 1000 / 60} minutes`,
      })
    })
  },
  methods: {
    updateActiveTab(activeTab) {
      this.tabIndex = this.tabData.findIndex(tab => tab.id === activeTab || (!activeTab && tab.default))
    },
    handleTabChange(tabIndex) {
      const activeTab = this.tabData[tabIndex]

      this.$router.replace({ name: 'leads-gen-ai', query: { tab: activeTab.id } })
    },
  },
}
</script>

<style lang="scss">
.tab-description {
  font-size: 18px;
  line-height: 23px;
  max-width: 786px;
  margin-bottom: 2rem;

  span {
    font-weight: bold;
    color: #8d82fd
  }
}
.tablist {
  position: relative;
  top: -20px;
  margin-top: 10px;

  .tablist-nav-wrapper {
    .nav-tabs {
      .nav-item {
        a {
          min-width: 167px;
          color: #686767;
          font-size: 16px;
          font-weight: 700;

          &.active {
            color: #292929;
            font-weight: bold;
          }

          &::after {
            background: #292929 !important;
          }
        }
      }
    }
  }
}

@media(max-width: 576px) {
  .tablist {
    top: 0
  }
}
</style>
