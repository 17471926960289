export const SET_IS_ENABLE_CREATE_WISHLIST = 'setIsEnableCreateWishlist'
export const SET_IS_ENABLE_REMOVE_WISHLIST_BUTTON = 'setIsEnableRemoveWishlistButton'
export const SET_IS_CREATE_WISHLIST_BTN_DISABLED = 'setIsCreateWishlistBtnDisabled'

export const state = {
  isEnableCreateWishlist: false,
  isEnableRemoveWishlistButton: false,
  isCreateWishlistBtnDisabled: false,
}

export const mutations = {
  [SET_IS_CREATE_WISHLIST_BTN_DISABLED](state, payload) {
    state.isCreateWishlistBtnDisabled = payload
  },
  [SET_IS_ENABLE_CREATE_WISHLIST](state, payload) {
    state.isEnableCreateWishlist = payload
  },
  [SET_IS_ENABLE_REMOVE_WISHLIST_BUTTON](state, payload) {
    state.isEnableRemoveWishlistButton = payload
  },
}

export default {
    state,
    mutations,
}
