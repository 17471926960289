<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <progress-bar
      v-if="progressBar"
      :upload-percentage="progressBar.uploadPercentage"
      :upload-message="progressBar.uploadMessage"
      :show-progress="progressBar.showProgress"
    />

    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />

    <!-- knowledge center button -->
    <knowledge-center-floating-button />

    <!-- Cart Conflict Modal Confirm -->
    <cart-conflict
      v-if="cartConflict"
      :product="cartConflict.product"
      :payload="cartConflict.payload"
      :query-params="cartConflict.queryParams"
      :conflict-message="cartConflict.message"
      :location="cartConflict.location"
      :conflict-resolve-callback="cartConflict.conflictResolveCallback"
      :skip-update-cart-count="cartConflict.skipUpdateCartCount"
      @cancel-conflict="cartConflict=!cartConflict"
    />

    <order-success-modal
      v-if="orderSuccessPopupContext"
      :order-ids="orderSuccessPopupContext.orderIds"
    />
    <check-list-popup
      v-if="isLoggedIn && showPopup"
    />
    <guest-sign-up
      v-if="isLoggedIn && isGuest"
    />
    <tool-kit ref-id="appTour" />
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { provideToast } from 'vue-toastification/composition'
import { watch, ref } from '@vue/composition-api'
import { useWindowSize, useCssVar } from '@vueuse/core'
import useAppConfig from '@/@core/app-config/useAppConfig'
import ScrollToTop from '@/@core/components/scroll-to-top/ScrollToTop.vue'

import store from '@/store'
import analytics from '@/@core/utils/analytics'

import { $themeColors, $themeBreakpoints, $themeConfig } from '../themeConfig'
import CheckListPopup from './views/apps/dashboard/checkListPopup.vue'
import { showOnboardChecklist } from './@core/utils/utils'
import GuestSignUp from './views/apps/authentication/GuestSignUp.vue'
import { TOOLKIT_PAGE } from './constants'
import KnowledgeCenterFloatingButton from './@core/components/floating-button/KnowledgeCenterFloatingButton.vue'

const CartConflict = () => import('@/views/apps/error/CartConflict.vue')
const ProgressBar = () => import('@/views/components/progress/ProgressBar.vue')
const OrderSuccessModal = () => import('@/views/components/modal/OrderSuccessModal.vue')

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  name: 'App',
  components: {
    // Cart conflicts
    CartConflict,

    // Modals
    OrderSuccessModal,

    // ProgressBar
    ProgressBar,

    // Layouts
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    CheckListPopup,
    GuestSignUp,
    KnowledgeCenterFloatingButton,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    isLoggedIn() {
      const isDashboard = this.$route.name === 'dashboard'
      return this.$store.getters.isAuthenticated && !isDashboard
    },
    showPopup() {
      return showOnboardChecklist()
    },
    isGuest() {
      return this.$store.getters.isGuest
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement)
        .value
        .trim()
    }
    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement)
        .value
        .slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup(props, { root }) {
    const {
      skin,
      skinClasses,
    } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    root.$progressBar = ref(null)
    root.$cartConflict = ref(null)
    root.$orderSuccessPopupContext = ref(null)

    return {
      skinClasses,
      enableScrollToTop,
      cartConflict: root.$cartConflict,
      orderSuccessPopupContext: root.$orderSuccessPopupContext,
      progressBar: root.$progressBar,
      showOnboardChecklist,
      TOOLKIT_PAGE,
    }
  },
  mounted() {
    analytics.init()
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/pages/page-common.scss';
</style>
