import analytics from '@/@core/utils/analytics'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { formatObject } from '@/@core/utils/utils'
import constants from '@/constants'
import { UPDATE_CART_ITEMS_COUNT } from '@/store/modules/shop.module'

export const checkIfInCart = (product, router, force = false) => {
    if (!force && product.isInCart) {
        analytics.track(
            constants.TRACKS.ACTIONS.VIEW_IN_CART,
            formatObject(product),
        )
        router.push({ name: 'checkout' })
    }
}

export const handleCartonProduct = (product, payload, quantity) => {
    if (typeof quantity !== 'undefined') {
        quantity = parseInt(quantity)
        if (isNaN(quantity) || quantity < 1) {
            apiToastWarning('Please enter a valid quantity!')
            return true
        }
    } else {
        quantity = 1
    }
    payload.products.push({
        productId: product._id,
        quantity,
    })
}

export const handleProductItems = (product, items, payload) => {
    const payloadProductsItems = []
    let hasValue = false
    items.forEach(item => {
        for (const k in item) {
            if (item.hasOwnProperty(k) && k !== 'color') {
                payloadProductsItems.push({
                    sku: item[k].skuId,
                    value: item[k].value,
                })
                if (item[k].value > 0) {
                    hasValue = true
                }
            }
        }
    })
    const payloadProducts = [
        {
            productId: product._id,
            items: payloadProductsItems,
        },
    ]
    if (!hasValue) {
        throw Error('Please enter valid values for variants!')
    } else {
        payload.products = payloadProducts
    }
}

export const checkIfWishlistItemInCart = (product, router) => {
    const productIsInCart = product?.isInCart || product?.productInfo?.isInCart
    if (productIsInCart) {
        router.push({ name: 'checkout' })
    }
}

export const handleAddToCartSuccess = (res, product, store) => {
    product.isInCart = true
        // Update cart items count
    store.commit(
        UPDATE_CART_ITEMS_COUNT,
        store.state.shop.cartItemsCount + 1,
    )
    apiToastSuccess(res.data.message || 'Add product to cart!')
    analytics.track(
        constants.TRACKS.ACTIONS.ADD_TO_CART,
        formatObject(product),
    )
}
