<template>
  <b-card
    class="lead-card ecommerce-card ecom overflow-visible"
    no-body
  >
    <div class="card-square-image d-block">
      <img
        :src="lead.smallLogoUrl || getDefaultProductImage(300, 250)"
        alt="Logo"
        class="img img-responsive img-fluid w-100"
        @error="imageError"
      >
    </div>
    <b-card-body class="d-flex flex-column custom-padding">
      <div class="flex-grow-1">
        <div class="lead-name custom-font-size">
          {{ lead.storeName }}
        </div>
        <div class="lead-category custom-font-size">
          {{ lead.category }}
        </div>
        <hr class="lead-divider">
        <b-card-text class="lead-location">
          <feather-icon
            icon="MapPinIcon"
            size="12"
          />
          <span class="country-text custom-font-size"> {{ displayLocation(lead) }}</span>
        </b-card-text>
        <div class="lead-insights">
          <div class="lead-insights-title mt-1">
            Leads Insights
          </div>
          <b-card-text class="text mb-0 custom-font-size">
            <strong class="mt-1">Store Type:</strong>
            {{ lead.storeTypes.join(', ') || 'N/A' }}
          </b-card-text>
          <b-card-text class="text mt-1 mb-1 custom-font-size">
            <strong>AVG Retail Price:</strong>
            {{ lead.averageRetailPrice.join(', ') || 'N/A' }}
          </b-card-text>
        </div>
      </div>
      <div class="button-container">
        <b-button
          :disabled="isLoading"
          variant="primary"
          class="details-btn d-flex align-items-center justify-content-center w-100 "
          @click="handleViewDetails"
        >
          <b-spinner
            v-if="isLoading"
            class="mr-1"
            small
            label="Loading..."
          />
          <span v-if="isLoading">Loading...</span>
          <span v-else>See details and contacts</span>
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
 BButton, BCard, BCardBody, BCardText, BSpinner,
} from 'bootstrap-vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { getDefaultProductImage } from '@/@core/utils/utils'

export default {
  name: 'LeadAICard',
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BSpinner,
  },
  props: {
    lead: {
      type: Object,
      default: () => ({
        storeName: '',
        category: '',
        country: '',
        locality: '',
        storeTypes: [],
        averageRetailPrice: [],
      }),
      required: true,
    },
    onViewDetails: {
      type: Function,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      getDefaultProductImage,
      isLoading: false, // State to track loading
    }
  },
  methods: {
    async handleViewDetails() {
      this.isLoading = true // Start loading
      try {
        analytics.track(
          constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_CONTACT_BUTTON,
          {
            leadId: this.lead.id,
            lead_storeName: this.lead.storeName,
          },
        )
        // Wait for the parent to process the emitted event
        await this.onViewDetails(this.lead.id)
      } catch (error) {
        console.error('Error while viewing details:', error)
      } finally {
        this.isLoading = false // Stop loading
      }
    },
    displayLocation(leadDetails) {
      if (!leadDetails.country) {
        return 'N/A'
      }
      if (!leadDetails.locality) {
        return leadDetails.country
      }
      return `${leadDetails.country} - ${leadDetails.locality}`
    },
    imageError(e) {
      e.target.src = getDefaultProductImage(300, 250)
    },
  },
}
</script>
<style lang="scss">
.custom-padding {
  padding: 16px;
}
.details-btn {
  padding: 0.786rem;
}
.custom-font-size {
  font-size: 12px;
  line-height: normal;
}

.card-square-image {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;

  img {
    position: absolute;
    aspect-ratio: 1;
    object-fit: contain;
    left: 0;
  }
}

@media (max-width: 1440px) {
  .custom-padding {
    padding: 16px;
  }
  .details-btn {
    font-size: 11px;
    padding: 0.786rem 0.25rem;
  }
}
</style>
