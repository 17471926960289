<template>
  <div
    class="h-100"
    :class="{ 'd-flex justify-content-center empty-container': isEmpty }"
  >
    <b-modal
      id="modal-lead-details"
      hide-footer
      centered
      size="lg"
      body-class="d-flex justify-content-center align-items-center"
    >
      <LeadAIError
        v-if="freeCustomerError"
        class="m-2"
        v-bind="freeCustomerErrorProps"
      />
      <LeadAIError
        v-else-if="paidCustomerError"
        class="m-2"
        v-bind="paidCustomerErrorProps"
      />
    </b-modal>
    <template v-if="leads.length">
      <div class="grid-container">
        <LeadAICard
          v-for="lead in leads"
          :key="lead.id"
          :lead="lead"
          :on-view-details="fetchLeadDetails"
        />
      </div>
      <div class="pagination-container">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalLeads"
              :per-page="pageSize"
              page-class="hidden"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChanged"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </template>
    <LeadAIError
      v-else
      title="Don't see the leads you desire?"
      top-description="Kingpin's AI is being trained before public launch in January 2025."
      bottom-description="Book a call with our Customer Success team to discover how we can source targeted prospects tailored for your business!"
      button-text="Get more tailored leads"
      contact-link="https://calendar.app.google/XgMuYFpJUtaXxHqe7"
    />
  </div>
</template>

<script>
import {
 BRow, BCol, BPagination, BModal,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import LeadAIError from '@/views/apps/lead-ai/LeadAIError.vue'
import { FETCH_LEAD_DETAIL } from '@/store/modules/leadai.module'
import { apiToastWarning } from '@/@core/utils/toast'
import LeadAICard from './LeadAICard.vue'

export default {
  components: {
    BPagination,
    BModal,
    FeatherIcon,
    LeadAIError,
    LeadAICard,
    BRow,
    BCol,
  },
  props: {
    leads: {
      type: Array,
      default: () => [],
    },
    totalLeads: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      error: null,
    }
  },
  computed: {
    isEmpty() {
      return this.leads.length === 0
    },
    freeCustomerError() {
      return this.error === 'trial'
    },
    paidCustomerError() {
      return this.error === 'paid'
    },
    freeCustomerErrorProps() {
      return {
        title: 'Need more leads?',
        topDescription:
          "You've accessed the contact details of 3 leads using Leads AI, which is the limit for non-paying Kingpin customers.",
        bottomDescription:
          'Book a meeting with our Sales Team to explore how Kingpin Leads AI can help you target potential clients that are a perfect fit for you.',
        buttonText: 'Get more tailored leads',
        contactLink: 'https://calendar.app.google/XgMuYFpJUtaXxHqe7',
      }
    },
    paidCustomerErrorProps() {
      return {
        title: 'Need more leads?',
        topDescription:
          "You've accessed the contact details of 10 leads using Leads AI, which is the limit for Kingpin customers.",
        bottomDescription:
          'Book a meeting with our Customer Success Team to explore how Kingpin Leads AI can help you target potential clients that are a perfect fit for you.',
        buttonText: 'Get more tailored leads',
        contactLink: 'https://calendar.app.google/XgMuYFpJUtaXxHqe7',
      }
    },
  },
  methods: {
    async fetchLeadDetails(leadId) {
      try {
        await this.$store.dispatch(FETCH_LEAD_DETAIL, leadId)
        const newRouteName = this.$route.name === 'leads-ai' ? 'leads-ai/detail' : 'leads-gen-ai-detail'
        this.$router.push({ name: newRouteName, params: { leadId } })
      } catch (err) {
        if (err.response?.status === 410) {
          this.$bvModal.show('modal-lead-details')
          this.error = err.response.data?.details?.leadsAiType || 'Unknown error'
        } else {
          console.error(err)
          apiToastWarning(
            'Failed to load the lead details page. Please try again.',
          )
        }
      }
    },
    pageChanged(page) {
      this.$emit('pageChanged', page)
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(178px, 1fr));
  gap: 20px;
}

@media (max-width: 1439px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(178px, 1fr));
    gap: 20px;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.country-text {
  margin-left: 3px;
  font: manrope;
}

.mild-divider {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 0;
}

ul.pagination li[role='separator'] {
  display: none;
}

.empty-container {
  margin-top: clamp(100px, 25%, 250px);
}
</style>
