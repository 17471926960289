<template>
  <div class="font-inter">
    <!-- Cancelled products -->
    <cart-error-popup
      v-if="showCancelledErrorToast"
      :error-title="errorTitle[ERRORS.CANCELLED]"
      remove-all-btn-title="Remove all cancelled items"
      @on-cross-icon-click="onClickCrossIcon(ERRORS.CANCELLED)"
      @isRemovingItems="isRemovingCancelledItems"
      @on-remove-all-click="removeAllCancelledItems"
    />
    <cart-error-popup
      v-if="showOutOfStockErrorToast"
      :error-title="errorTitle[ERRORS.OUT_OF_STOCK]"
      remove-all-btn-title="Remove all out of stock items"
      @on-cross-icon-click="onClickCrossIcon(ERRORS.OUT_OF_STOCK)"
      @isRemovingItems="isRemovingOutOfStockItems"
      @on-remove-all-click="removeAllOutOfStockItems"
    />
    <cart-error-popup
      v-if="showZeroQtyErrorToast"
      :error-title="errorTitle[ERRORS.ZERO_QTY]"
      remove-all-btn-title="Remove all items with 0 quantities"
      @on-cross-icon-click="onClickCrossIcon(ERRORS.ZERO_QTY)"
      @isRemovingItems="isRemovingZeroQtyItems"
      @on-remove-all-click="removeAllZeroQtyItems"
    />
    <cart-error-popup
      v-if="showDecimalQtyErrorToast"
      :error-title="errorTitle[ERRORS.DECIMAL_QTY]"
      :should-show-remove-all-btn="false"
      remove-all-btn-title="Remove all items with 0 quantities"
      @on-cross-icon-click="onClickCrossIcon(ERRORS.DECIMAL_QTY)"
      @isRemovingItems="false"
    />
  </div>
</template>

<script>
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
 DELETE_PRODUCTS_BY_CART_ID, LOAD_CARTS, PUSH_EXPANDED_CARTS, UPDATE_CART_ERRORS,
} from '@/store/modules/checkout-v2.module'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import CartErrorPopup from './CartErrorPopup.vue'

const { PRODUCT_STATUS } = c

const ERRORS = {
    CANCELLED: 'cancelled',
    OUT_OF_STOCK: 'outofstock',
    ZERO_QTY: 'zeroqty',
    DECIMAL_QTY: 'decimalQty',
}

export default {
    name: 'CartErrors',
    components: { CartErrorPopup },
    mixins: [UserRoleMixin],
    props: {
      cartId: {
        type: String,
        default: '',
      },
      cartProducts: {
        type: Array,
        default: () => [],
      },
    },
    data() {
        return {
            cancelledItemsCount: 0,
            outOfStockItemsCount: 0,
            zeroQtyItemsCount: 0,
            decimalQtyItemsCount: 0,
            showCancelledErrorToast: false,
            showOutOfStockErrorToast: false,
            showZeroQtyErrorToast: false,
            showDecimalQtyErrorToast: false,
            cancelledProductIds: [],
            outOfStockProductIds: [],
            zeroQtyProductIds: [],
            decimalQtyProductIds: [],
            isRemovingCancelledItems: false,
            isRemovingOutOfStockItems: false,
            isRemovingZeroQtyItems: false,
            ERRORS,
        }
    },
    computed: {
      errorTitle() {
        return {
          [ERRORS.CANCELLED]: this.cancelledItemsCount > 1 ? `${this.cancelledItemsCount} items are cancelled.` : `${this.cancelledItemsCount} item is cancelled.`,
          [ERRORS.OUT_OF_STOCK]: this.outOfStockItemsCount > 1 ? `${this.outOfStockItemsCount} items are out of stock.` : `${this.outOfStockItemsCount} item is out of stock.`,
          [ERRORS.ZERO_QTY]: this.zeroQtyItemsCount > 1 ? `${this.zeroQtyItemsCount} items have 0 quantities.` : `${this.zeroQtyItemsCount} item has 0 quantities.`,
          [ERRORS.DECIMAL_QTY]: this.decimalQtyItemsCount > 1 ? `${this.decimalQtyItemsCount} items have decimal quantities.` : `${this.decimalQtyItemsCount} item has decimal quantity.`,
        }
      },
    },
    watch: {
        cartProducts: {
            handler() {
                this.loadErrors()
            },
            deep: true,
        },
    },
    created() {
        this.loadErrors()
    },
    methods: {
        loadErrors() {
          this.resetCounters()
          this.cartProducts.forEach(product => {
            if (product.status === PRODUCT_STATUS.CANCELLED.VALUE) {
              this.cancelledItemsCount++
              this.cancelledProductIds.push(product.productId)
            } else if (product?.hasError) {
              this.outOfStockItemsCount++
              this.outOfStockProductIds.push(product.productId)
            } else if (!product?.lineTotalQuantity) {
              this.zeroQtyItemsCount++
              this.zeroQtyProductIds.push(product.productId || product._id)
            } else if (product.lineTotalQuantity % 1 !== 0) {
              this.decimalQtyItemsCount++
              this.decimalQtyProductIds.push(product.productId)
            }
          })
            this.toggleErrorToast()
        },
        resetCounters() {
            this.cancelledItemsCount = 0
            this.outOfStockItemsCount = 0
            this.zeroQtyItemsCount = 0
            this.decimalQtyItemsCount = 0
            this.cancelledProductIds = []
            this.outOfStockProductIds = []
            this.zeroQtyProductIds = []
            this.decimalQtyProductIds = []
        },
        resetLoaders() {
            this.isRemovingCancelledItems = false
            this.isRemovingOutOfStockItems = false
            this.isRemovingZeroQtyItems = false
        },
        toggleErrorToast() {
            this.showCancelledErrorToast = !!this.cancelledItemsCount
            this.showOutOfStockErrorToast = !!this.outOfStockItemsCount
            this.showZeroQtyErrorToast = !!this.zeroQtyItemsCount
            this.showDecimalQtyErrorToast = !!this.decimalQtyItemsCount
            const isCartHasAnError = this.showCancelledErrorToast || this.showOutOfStockErrorToast || this.showZeroQtyErrorToast || this.showDecimalQtyErrorToast
            if (isCartHasAnError)
              {
                this.$store.commit(PUSH_EXPANDED_CARTS, this.cartId)
              }
        },
        removeAllCancelledItems() {
            if (this.isRemovingCancelledItems) return
            this.isRemovingCancelledItems = true
            this.removeMultipleProducts(this.cancelledProductIds)
        },
        removeAllOutOfStockItems() {
            if (this.isRemovingOutOfStockItems) return
            this.isRemovingOutOfStockItems = true
            this.removeMultipleProducts(this.outOfStockProductIds)
        },
        removeAllZeroQtyItems() {
            if (this.isRemovingZeroQtyItems) return
            if (this.isBrand) {
            this.$emit('update:remove-products-brand-cart', this.zeroQtyProductIds)
            this.$store.commit(UPDATE_CART_ERRORS)
            this.loadErrors()
            this.isRemovingZeroQtyItems = true
               return
            }
            this.isRemovingZeroQtyItems = true
            this.removeMultipleProducts(this.zeroQtyProductIds)
        },
        removeMultipleProducts(productIds = []) {
            this.$store.dispatch(DELETE_PRODUCTS_BY_CART_ID, { cartId: this.cartId, productIds }).then(res => {
                apiToastSuccess(res.data.message)
                this.$store.dispatch(LOAD_CARTS)
                this.$store.commit(UPDATE_CART_ERRORS)
                this.resetLoaders()
            }).catch(err => {
                apiToastError(err)
            })
        },
        onClickCrossIcon(type) {
            switch (type) {
                case ERRORS.CANCELLED:
                    this.showCancelledErrorToast = false
                    break
                case ERRORS.OUT_OF_STOCK:
                    this.showOutOfStockErrorToast = false
                    break
                case ERRORS.ZERO_QTY:
                    this.showZeroQtyErrorToast = false
                    break
                case ERRORS.DECIMAL_QTY:
                    this.showDecimalQtyErrorToast = false
                    break
                default:
                    break
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$error-color: #AF504B;
</style>
