<template>
  <b-modal
    id="modal-cart-conflict"
    ok-variant="warning"
    ok-title="Yes, I understand"
    modal-class="modal-warning"
    centered
    title="Add to Cart"
    @ok="confirmConflict"
    @hide="$emit('cancel-conflict')"
  >
    <b-card-text>
      {{ conflictMessage }}
    </b-card-text>
  </b-modal>
</template>

<script>
import { BCardText, VBModal } from 'bootstrap-vue'
import { apiToastSuccess, apiToastWarning } from '@core/utils/toast'
import { assignPayloadProduct } from '@/@core/utils/utils'
import { ADD_PRODUCT_TO_CART, UPDATE_CART_ITEMS_COUNT } from '@/store/modules/shop.module'

export default {
  components: {
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  emits: ['confirm-conflict', 'cancel-conflict'],
  props: {
    product: {
      type: [Array, Object],
      default() {
        return {}
      },
    },
    payload: {
      type: Object,
      default() {
        return {}
      },
    },
    queryParams: {
      type: Object,
      default() {
        return {}
      },
    },
    location: {
      type: String,
      default: '',
    },
    conflictResolveCallback: {
      type: String,
      default: '',
    },
    conflictMessage: {
      type: String,
      default() {
        return ''
      },
    },
    skipUpdateCartCount: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$bvModal.show('modal-cart-conflict')
  },
  methods: {
    confirmConflict() {
      const products = this.extractProducts()

      const params = {
        shouldClearCart: true,
        ...this.queryParams,
      }

      const payload = {
        products,
        ...this.payload,
      }

      this.$store.dispatch(ADD_PRODUCT_TO_CART, { params, payload })
        .then(res => {
          if (this.location && this.conflictResolveCallback) {
            this.$root.$refs[this.location][this.conflictResolveCallback](this.product)
          }
          if (!this.skipUpdateCartCount) {
            this.updateCartItemsCount()
          }
          apiToastSuccess(res.data.message || 'Add product to cart!')
        })
        .catch(err => {
          apiToastWarning(err)
        })
        .finally(() => {
          this.$root.$cartConflict.value = null
        })
    },
    extractProducts() {
      let products = []
      if (Array.isArray(this.product)) {
        this.product.forEach(product => {
          if (typeof product._id === 'undefined') {
            products.push(...this.extractIndividualProducts(product))
          } else if (product.isCarton) {
            products.push({
              productId: product._id,
            })
          } else {
            products.push(...this.extractIndividualProducts(product))
          }
        })
      } else if (typeof this.product._id === 'undefined') {
        products = this.product.productInfo.isCarton
          ? [{ productId: this.product.productId }]
          : this.extractIndividualProducts(this.product.productInfo)
      } else if (this.product.isCarton) {
        products.push({
          productId: this.product._id,
        })
      } else {
        products = this.extractIndividualProducts(this.product)
      }

      return products
    },
    extractIndividualProducts(productInfo) {
      if (productInfo.isCarton) {
        return [{ productId: productInfo.productId }]
      } else {
        return assignPayloadProduct(productInfo.items, productInfo._id)
      }
    },
    updateCartItemsCount() {
      if (this.queryParams?.isSelectedAllProducts) {
        const totalProductsCount = this.$store.state.shop.totalProductsCount
        this.$store.commit(UPDATE_CART_ITEMS_COUNT, totalProductsCount)
      } else {
        const cartCount = Array.isArray(this.product) ? this.product.length : 1
        this.$store.commit(UPDATE_CART_ITEMS_COUNT, cartCount)
      }
    },
  },
}
</script>
