<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-header-close
    hide-footer
    size="lg"
    centered
  >
    <div class="pb-1 d-flex justify-content-between align-items-center">
      <div class="custom-modal-heading">
        {{ modalTitle }}
      </div>
      <feather-icon
        class="float-right close-x-icon"
        icon="XIcon"
        size="24"
        @click="closeSelectStoresPopup"
      />
    </div>
    <div>

      <stores-list
        :cart-values-list="storeList"
        :show-only-list="true"
        @update-selected-stores="onSelectedStores"
      />

      <div class="w-100 my-2 d-flex justify-content-center">
        <div>
          <b-button
            variant="outline-primary"
            @click="closeSelectStoresPopup"
          >
            Cancel
          </b-button>
        </div>
        <div class="ml-1">
          <b-button
            variant="primary"
            :disabled="isDownloading"
            @click="onClickDownload"
          >
            {{ isDownloading ? 'Downloading...' : okBtnText }}
          </b-button>
        </div>
      </div>

    </div>
  </b-modal>
</template>

  <script>
  import { BButton, BModal } from 'bootstrap-vue'
  import StoresList from './StoresList.vue'

  export default {
    name: 'SelectStoresModal',
    components: {
      StoresList,
      BModal,
      BButton,
    },
    props: {
      modalId: {
        type: String,
        required: true,
      },
      modalTitle: {
        type: String,
        default: 'Select stores',
      },
      okBtnText: {
        type: String,
        default: 'Download',
      },
      storeList: {
        type: Array,
        required: true,
        default: () => ([]),
      },
      isDownloading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedStores: [],
      }
    },
    emits: ['on-click-download'],
    methods: {
      closeSelectStoresPopup() {
        this.selectedStores = []
        this.$bvModal.hide(this.modalId)
      },
      onSelectedStores(list) {
        this.selectedStores = list
      },
      onClickDownload() {
        this.$emit('on-click-download', this.selectedStores)
      },
    },
  }
  </script>

  <style lang="scss">
  @import '~@core/scss/base/pages/app-ecommerce.scss';
  </style>

  <style lang="scss" scoped>
  @import "src/@core/scss/base/bootstrap-extended/_variables.scss";

  .checkout-options {
      .price-details {
        .price-title {
          font-weight: $font-weight-bolder;
          margin-bottom: 0.75rem;
          color: $dark-grey;
          font-size: 1.25rem;
        }

        .price-detail {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.75rem;
          color: $actions-text-grey;

          .detail-title {
            &.detail-total {
              font-weight: $font-weight-bolder;
            }
          }
          .total-price {
            color: $dark-grey;
            font-weight: $font-weight-bolder;
          }
        }
      }

    }

  .cart-order-details-card {
    background-color: $gray-200;

    .card-body {
      padding: 1rem;
    }
  }

  </style>
