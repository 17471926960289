<template>
  <div class="mt-1">
    <b-table
      v-for="(variant, variantIndex) in variants"
      :key="`variant-${variantIndex}`"
      class="product-variants-v2 table-responsive pb-1"
      thead-class="text-center"
      :items="[variant]"
      :fields="headings"
      stacked="md"
      responsive
    >
      <template #head(color)>
        <span
          :style="{'visibility': !!variantIndex && 'hidden' || 'visible'}"
          class="product-span-color"
        >Color/Size</span>
      </template>
      <template
        v-for="(heading, index) in headings"
        v-slot:[`cell(${heading})`]="variant"
      >
        <div :key="`${heading}${index}`">
          <div
            class="d-inline-flex justify-content-center flex-column"
          >
            <b-form-input
              :key="variant.item[heading].skuId"
              v-model.number="variant.item[heading].value"
              type="number"
              class="text-center zero-qty-error"
              :class="getInputClasses(variant, heading, isCarton)"
              min="0"
              :readonly="isDisabled || isProductValueDisabled(variant.item[heading].quantityAvailable)"
              :disabled="isDisabled || isProductValueDisabled(variant.item[heading].quantityAvailable)"
              @input="$emit('update-variants', variants)"
            />
            <div
              v-if="canShowMaxQty && hasProductMaxQuantity(variant.item[heading].quantityAvailable)"
              class="w-75 text-nowrap text-center color-actions-text-grey mt-2-px"
            >
              Max Qty: {{ variant.item[heading].quantityAvailable }} </div>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import { BFormInput, BTable } from 'bootstrap-vue'
import UserRoleMixinVue from '../../UserRoleMixin.vue'
import ProductVariantsMixinVue from '../../product/detail/ProductVariantsMixin.vue'

export default {
    name: 'ProductVariantsV2',
    components: {
        BTable,
        BFormInput,
    },
    mixins: [UserRoleMixinVue, ProductVariantsMixinVue],
    methods: {
      getInputClasses(variant, heading) {
        return { 'decimal-qty-error': !Number.isInteger(variant.item[heading].value), invalid: !this.isCarton && (this.isProductItemValid(variant.item[heading].value, variant.item[heading].quantityAvailable) || (variant.item[heading].value && variant.item[heading].value < this.minQtyPerSize)) }
      },
    },
}
</script>

<style lang="scss">
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
.product-variants-v2 {
  &::-webkit-scrollbar {
    height: 4px !important;
  }
  &::-webkit-scrollbar-track {
    background: #9F9F9F66;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: #9F9F9F;
    border-radius: 20px;
  }

    thead {
        tr {
            th {
                background-color: transparent !important;
                border: none !important;
                padding: 0.5rem 1rem 0 0;
            }
        }
    }

    tbody {
        tr {
            td {
                border: none !important;
                padding: 0.5rem 1rem 0 0;

                input {
                    width: 54px;
                }
            }
        }
    }

    th:first-child,
    td:first-child {
        position: sticky;
        left: 0;
        z-index: 100;
        background-color: $gray-50 !important;

    }

    td {
        padding: 1rem;
    }
}

.product-span-color {
    text-transform: capitalize;
    width: 80px;
    display: block;
}

.invalid {
    border-color: $red;

    &:focus {
        border-color: $red;
    }
}
</style>
