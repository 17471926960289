import { wishlistAction } from '@/constants'
import Vue from 'vue'

export const updateWishlists = (wishlists, action, wishlistId) => {
  if (
    [
      wishlistAction.ADD_TO_WISHLIST,
      wishlistAction.ADD_BY_BRAND,
    ].includes(action)
  ) {
    return wishlists ? [...wishlists, wishlistId] : [wishlistId]
  }
  if (action === wishlistAction.REMOVE_FROM_WISHLIST) {
    return wishlists
      ? wishlists.filter(wishlist => wishlist !== wishlistId)
      : []
  }
  return wishlists
}

export const handleOptimisticUpdateForWishlist = ({
  product,
  index,
  products,
  action,
  wishlistId,
}) => {
  if (!product) {
    console.error('Invalid product:', product)
    return
  }

  const updatedWishlists = updateWishlists(
    product.wishlists,
    action,
    wishlistId,
  )

  const idsDisabledToSelect = product?.idsDisabledToSelect ?? []

  const updatedProduct = {
    ...product,
    wishlists: updatedWishlists,
    idsDisabledToSelect: [...idsDisabledToSelect, wishlistId],
    isOpenedDropdown: false,
  }

  if (products && Array.isArray(products)) {
    if (index < 0 || index >= products.length) {
      console.error('Index out of bounds:', {
        index,
        productsLength: products.length,
      })
      return
    }
    Vue.set(products, index, updatedProduct)
  }

  return updatedProduct
}
