<template>
  <div
    class="d-flex h-auto align-items-center"
    :class="{ 'w-100': isBrandShopPage }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <b-dropdown
      :id="`wishlist-dropdown-${product._id}`"
      :class="showCheckBox ? 'pointerevents' : ''"
      :style="getStyleCardWidth"
      class="product-wishlist-dropdown w-100"
      :menu-class="isBrandShopPage ? 'w-100' : ''"
      variant="link"
      toggle-class="text-decoration-none p-0 m-0 text-capitalize intro-add-wishlist"
      no-caret
      :no-flip="!isProductDetailsPage"
      dropdown
      :block="isBrandShopPage"
      :offset="!isProductDetailsPage && !isBrandShopPage ? 64 - cardWidth : ''"
      :disabled="disabled"
      @show="handleEffect('show')"
      @hide="handleEffect('hide')"
    >
      <template #button-content>
        <div
          class="
            wishlist-icon-with-dropdown
            d-flex
            align-items-center
            justify-content-center
            h-100
          "
          :class="{
            'btn btn-outline-primary btn-sm': isProductDetailsPage,
            'wishlist-dropdown-width':
              !isProductDetailsPage && !isBrandShopPage,
            'w-100 align-items-center justify-content-between p-50 py-75 border-top border-1':
              isBrandShopPage,
          }"
        >
          <div
            v-if="isBrandShopPage"
            class="h4 m-0 selection-label text-truncate"
          >
            Selections
          </div>
          <div
            class="heart-with-count-badge"
            :class="{ 'ml-auto mr-10': isBrandShopPage }"
          >
            <div
              class="heart-with-count-badge"
              :class="{ 'ml-auto mr-10': isBrandShopPage }"
            >
              <feather-icon
                icon="HeartIcon"
                :size="heartIconSize"
                :class="{ 'text-danger fill-danger': productWishlistsCount }"
              />
              <div
                v-if="productWishlistsCount"
                class="
                  count-badge
                  text-white
                  bg-primary
                  rounded-pill
                  border border-white
                  d-flex
                  align-items-center
                  justify-content-center
                "
                :class="{ 'count-badge-product-details': isProductDetailsPage }"
              >
                <div>
                  {{ productWishlistsCount }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isProductDetailsPage"
            class="pl-10-px"
          >
            {{ getWishlistTitle(!isSelections) }}
          </div>
          <feather-icon
            :icon="
              !!product.isOpenedDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'
            "
            size="20"
            class="ml-5-px"
          />
        </div>
      </template>
      <template
        v-if="
          productWishlistsCreatedByRetailer &&
            productWishlistsCreatedByRetailer.length
        "
      >
        <b-dropdown-header
          v-if="!isSelections"
          :id="'my_wishlists' + product._id"
          class="bg-gray"
        >
          My wishlists
        </b-dropdown-header>
        <b-dropdown-item
          v-for="wishlist in productWishlistsCreatedByRetailer"
          :key="product._id + wishlist._id"
          :disabled="getDisabled(product, wishlist)"
          @click="onSelectWishlistOnProduct(wishlist)"
        >

          <div class="d-flex align-items-center">
            <div class="wishlist-tick-icon">
              <feather-icon
                v-if="
                  product.wishlists.includes(wishlist._id) ||
                    forceShowCheckIcons
                "
                icon="CheckIcon"
                size="16"
                class="text-success"
              />
            </div>
            <div class="entity-name-text">
              {{ wishlist.name }}
            </div>
          </div>
        </b-dropdown-item>
      </template>
      <template
        v-if="
          productWishlistsSharedByBrand && productWishlistsSharedByBrand.length
        "
      >
        <b-dropdown-header
          v-if="!isSelections"
          :id="'shared_by_brand' + product._id"
        >
          Shared by brand
        </b-dropdown-header>
        <b-dropdown-item
          v-for="wishlist in productWishlistsSharedByBrand"
          :key="product._id + wishlist._id"
          :disabled="getDisabled(product, wishlist)"
          @click="onSelectWishlistOnProduct(wishlist)"
        >
          <div class="d-flex align-items-center">
            <div class="wishlist-tick-icon">
              <feather-icon
                v-if="
                  product.wishlists.includes(wishlist._id) ||
                    forceShowCheckIcons
                "
                icon="CheckIcon"
                size="16"
                class="text-success"
              />
            </div>
            <div class="entity-name-text">
              {{ wishlist.name }}
            </div>
          </div>
        </b-dropdown-item>
      </template>
      <b-dropdown-item @click="createWishlist()">
        <div>
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          <span
            class="entity-name-text"
          >Create {{ getWishlistTitle(!isSelections).toLowerCase() }}</span>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import analytics from '@/@core/utils/analytics'
import { getWishlistTitle } from '@/@core/utils/utils'
import constants from '@/constants'
import { SET_IS_NO_HOVER_PRODUCT_CARD } from '@/store/modules/shop.module'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import { BDropdown, BDropdownHeader, BDropdownItem } from 'bootstrap-vue'

const { PRODUCTS_PAGE_WISHLISTS } = constants.TRACKS.ACTIONS

export default {
  name: 'ProductWishlistsDropdown',
  components: {
    BDropdown,
    BDropdownHeader,
    BDropdownItem,
  },
  mixins: [UserRoleMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    showCheckBox: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    wishlists: {
      type: Array,
      default: () => [],
    },
    cardWidth: {
      type: Number,
      default: 0,
    },
    isProductDetailsPage: {
      type: Boolean,
      default: false,
    },
    isRecommendedProduct: {
      type: Boolean,
      default: false,
    },
    isOpenedDropdown: {
      type: Boolean,
      default: false,
    },
    isSelections: {
      type: Boolean,
      default: false,
    },
    productWishlistsCount: {
      type: Number,
      default: 0,
    },
    forceShowCheckIcons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productWishlistsCreatedByRetailer: [],
      productWishlistsSharedByBrand: [],
      getWishlistTitle,
      isActiveDropdown: false,
    }
  },
  computed: {
    isBrandShopPage() {
      return this.isSelections && !this.isProductDetailsPage
    },
    heartIconSize() {
      return this.isProductDetailsPage ? '20' : '24'
    },
    getStyleCardWidth() {
      if (this.isBrandShopPage) {
        return {}
      }
      if (this.isProductDetailsPage) {
        return {
          '--card-width': '250px',
        }
      }

      if (!this.cardWidth) {
        return {}
      }
      return {
        '--card-width': `${this.cardWidth}px`,
      }
    },
    pageType() {
      if (this.isProductDetailsPage) {
        return 'Product details page'
      } else if (this.isRecommendedProduct) {
        return 'Recommended products page'
      } else {
        return 'Collection products page'
      }
    },
  },
  watch: {
    wishlists: {
      handler() {
        this.setWishlistsForTheDropdown()
      },
      immediate: true,
    },
  },
  mounted() {
    window.dispatchEvent(new Event('resize'))
  },
  methods: {

    handleEffect(effect) {
      if (effect === 'hide') {
        this.onToggleWishlistDropdown(false)
      } else {
        this.onToggleWishlistDropdown(true)
      }
    },
    getDisabled(product, wishlist) {
      return !!product?.idsDisabledToSelect?.includes(wishlist._id)
    },
    onMouseEnter() {
      this.isActiveDropdown = true
      this.$store.commit(SET_IS_NO_HOVER_PRODUCT_CARD, true)
    },
    onMouseLeave() {
      this.isActiveDropdown = false
      if (!this.isOpenedDropdown) {
        this.$store.commit(SET_IS_NO_HOVER_PRODUCT_CARD, false)
      }
    },
    onSelectWishlistOnProduct(wishlist) {
      this.$emit('select-wishlist-on-product', wishlist)
      const action = this.product.wishlists.includes(wishlist._id)
        ? 'REMOVED_PRODUCT_FROM'
        : 'ADDED_PRODUCT_TO'
      const wishlistType = this.isBrand ? 'SELECTION' : 'WISHLIST'
      const role = this.isBrand ? 'BRAND' : 'RETAILER'
      const source = this.isRetailer && wishlist.isBrandInitiated
          ? `SHARED_${wishlistType}`
          : `MY_${wishlistType}`
      const trackEvent = `${role}_${action}_A_${wishlistType}_BY_CLICKING_A_${source}_IN_THE_${wishlistType}S_DROPDOWN`

      analytics.track(PRODUCTS_PAGE_WISHLISTS[trackEvent], {
        product: this.product,
        wishlist,
        pageType: this.pageType,
      })
    },
    createWishlist() {
      this.$emit('create-wishlist')
      const trackEvent = this.isBrand
        ? PRODUCTS_PAGE_WISHLISTS.BRAND_CLICKS_CREATE_SELECTION_IN_THE_SELECTIONS_DROPDOWN
        : PRODUCTS_PAGE_WISHLISTS.RETAILER_CLICKS_CREATE_WISHLIST_IN_THE_WISHLISTS_DROPDOWN
      analytics.track(trackEvent, {
        product: this.product,
        pageType: this.pageType,
      })
    },
    onToggleWishlistDropdown(isOpened = false) {
      this.$emit('toggle-wishlist-dropdown', isOpened)
      if (isOpened) {
        const trackEvent = this.isBrand
          ? PRODUCTS_PAGE_WISHLISTS.BRAND_OPENS_THE_SELECTIONS_DROPDOWN_BY_CLICKING_ON_THE_HEART_ICON
          : PRODUCTS_PAGE_WISHLISTS.RETAILER_OPENS_THE_WISHLISTS_DROPDOWN_BY_CLICKING_ON_THE_HEART_ICON
        analytics.track(trackEvent, {
          product: this.product,
          pageType: this.pageType,
        })
      } else {
        const trackEvent = this.isBrand
          ? PRODUCTS_PAGE_WISHLISTS.BRAND_CLOSES_THE_SELECTIONS_DROPDOWN
          : PRODUCTS_PAGE_WISHLISTS.RETAILER_CLOSES_THE_WISHLISTS_DROPDOWN
        analytics.track(trackEvent, {
          product: this.product,
          pageType: this.pageType,
        })
        if (!this.isActiveDropdown) this.$store.commit(SET_IS_NO_HOVER_PRODUCT_CARD, false)
      }
    },
    setWishlistsForTheDropdown() {
      if (!this.isSelections) {
        this.productWishlistsCreatedByRetailer = this.wishlists.filter(
          wishlist => !wishlist?.isBrandInitiated,
        )
      }
      this.productWishlistsSharedByBrand = this.wishlists.filter(
        wishlist => wishlist?.isBrandInitiated,
      )
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/base/bootstrap-extended/utilities';
@import '@/@core/scss/base/bootstrap-extended/include';

.product-wishlist-dropdown {
  .selection-label {
    color: #383d6b;
  }
  .wishlist-dropdown-width {
    width: 60px;
  }

  .wishlist-icon-with-dropdown {
    .heart-with-count-badge {
      position: relative;

      .count-badge {
        position: absolute;
        bottom: -2px;
        right: -8px;
        font-size: 0.8rem;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center;
        align-items: center;
      }

      .count-badge-product-details {
        width: 16px;
        height: 16px;
      }
    }

    .fill-danger {
      fill: $danger;
    }
  }

  .dropdown-menu {
    max-height: 350px;
    overflow-y: auto;
    margin-top: 5px;
    width: var(--card-width);
    z-index: 20;

    .dropdown-header {
      font-weight: 700;
      line-height: unset;
      background-color: $gray-300;
    }

    .wishlist-tick-icon {
      width: 20px;
    }
    .entity-name-text {
      text-wrap: wrap;
    }
  }
}
</style>
