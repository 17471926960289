<template>
  <div>
    <h1 class="title">
      Unlock your ideal leads <span>with Leads AI</span>
    </h1>
    <p class="sub-title">
      Share with AI your leads preferences and get instant, tailored results that will <span>supercharge your sales game!</span>
    </p>
    <tailored-leads-form />
  </div>
</template>
<script>
import TailoredLeadsForm from './TailoredLeadsForm.vue'

export default {
  name: 'LeadsAiTab',
  components: {
    TailoredLeadsForm,
  },
}
</script>
<style lang="scss">
@import '@/@core/scss/base/bootstrap-extended.scss';

.title {
  font-size: 32.471px;
  font-weight: normal;
  color: $body-color;
  margin-top: 23px;
  line-height: 42px;
  margin-bottom: 9px;
  position: relative;
  left: -2px;
  span {
    color: #8d82fd;
    font-weight: initial;
  }
}

.sub-title {
  font-size: 18px;
  max-width: 575px;
  margin-bottom: 22px;

  span {
    color: #8d82fd;
    font-weight: bold;
    line-height: 23px;
  }
}
</style>
