<template>
  <b-table
    class="product-variants table-responsive mt-1"
    :items="variants"
    :fields="headings"
    stacked="md"
    responsive
  >
    <template #head(color)>
      <span class="product-span-color">Color/Size</span>
    </template>
    <template
      v-for="(heading, index) in headings"
      v-slot:[`cell(${heading})`]="variant"
    >
      <div :key="`${heading}${index}`">
        <template v-if="heading === COLOR">
          {{ variant.item[heading] }}
        </template>
        <div
          v-if="heading !== COLOR"
          class="d-inline-flex justify-content-center flex-column"
        >
          <b-form-input
            :key="variant.item[heading].skuId"
            v-model.number="variant.item[heading].value"
            type="number"
            :class="getInputClasses(variant, heading)"
            min="0"
            class="w-75 zero-qty-error"
            :readonly="isDisabled || isProductValueDisabled(variant.item[heading].quantityAvailable)"
            @input="$emit('update-variants', variants)"
          />
          <div
            v-if="canShowMaxQty && hasProductMaxQuantity(variant.item[heading].quantityAvailable)"
            class="w-75 text-nowrap text-center color-actions-text-grey mt-2-px"
          >
            Max Qty: {{ variant.item[heading].quantityAvailable }} </div>
        </div>
      </div>
    </template>
  </b-table>
</template>
<script>
import { BFormInput, BTable } from 'bootstrap-vue'
import UserRoleMixinVue from '../../UserRoleMixin.vue'
import ProductVariantsMixinVue from './ProductVariantsMixin.vue'

export default {
  name: 'ProductVariants',
  components: {
    BTable,
    BFormInput,
  },
  mixins: [UserRoleMixinVue, ProductVariantsMixinVue],
  methods: {
    getInputClasses(variant, heading) {
      return { 'decimal-qty-error': !Number.isInteger(variant.item[heading].value), invalid: !this.isCarton && (this.isProductItemValid(variant.item[heading].value, variant.item[heading].quantityAvailable) || (variant.item[heading].value && variant.item[heading].value < this.minQtyPerSize)) }
    },
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
.product-variants {
  input.form-control {
    min-width: 50px;
  }
  th:first-child, td:first-child{
    position: sticky;
    left: 0;
    z-index: 9;
  }
  td {
    padding: 1rem;
  }
}
.product-span-color{
  text-transform: capitalize;
  width: 80px;
  display: block;
}
.has-error {
  .invalid {
  border-color: $red;
  &:focus {
    border-color: $red;
  }
}
}

</style>
