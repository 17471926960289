<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M14.025 7.275L9.525 11.775C9.375 11.925 9.225 12 9 12C8.775 12 8.625 11.925 8.475 11.775L3.975 7.275C3.675 6.975 3.675 6.525 3.975 6.225C4.275 5.925 4.725 5.925 5.025 6.225L9 10.2L12.975 6.225C13.275 5.925 13.725 5.925 14.025 6.225C14.325 6.525 14.325 6.975 14.025 7.275Z"
      fill="black"
    />
    <mask
      id="mask0_2052_2666"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="3"
      y="6"
      width="12"
      height="6"
    >
      <path
        d="M14.025 7.275L9.525 11.775C9.375 11.925 9.225 12 9 12C8.775 12 8.625 11.925 8.475 11.775L3.975 7.275C3.675 6.975 3.675 6.525 3.975 6.225C4.275 5.925 4.725 5.925 5.025 6.225L9 10.2L12.975 6.225C13.275 5.925 13.725 5.925 14.025 6.225C14.325 6.525 14.325 6.975 14.025 7.275Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2052_2666)">
      <rect
        width="18"
        height="18"
        fill="#757575"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ArrowIcon',
}
</script>
