import { computed, watch } from '@vue/composition-api'
import store from '@/store'
import { GET_PRODUCTS_IDS } from '@/store/modules/collection.module'
import {
  UPDATE_SELECT_COUNT,
  UPDATE_IS_SELECTED_ALL_PRODUCTS,
} from '@/store/modules/shop.module'
import { apiToastWarning } from '@core/utils/toast'
import { parseErrorObject } from '@/@core/utils/utils'

export const useSelections = (
  products,
  getFetchParams,
  indexes,
  shipWishlist,
  toggleRemoveAllBtn,
) => {
  const getProductIds = (...args) => store.dispatch(GET_PRODUCTS_IDS, ...args)

  watch(indexes, newIndexes => {
    store.commit(UPDATE_SELECT_COUNT, newIndexes.length)
  })

  const fetchProductIdsUnderCurrentFilters = async (resetFilters = false) => {
    try {
      const fetchParams = getFetchParams(resetFilters, shipWishlist)
      const productIds = await getProductIds(fetchParams, shipWishlist)
      return productIds.data.data
    } catch (error) {
      console.error('Error in fetching all product ids', error)
      const errorTitle = parseErrorObject(error) ?? ''
      if (!errorTitle.includes('No wishlist products found')) {
        apiToastWarning(error)
      }
      return []
    }
  }

  const isSelectedAllOnScreen = computed(() => {
    if (products.value?.length === 0) {
      return false
    }
    const displayedProductIds = products.value.flatMap(groupedProduct => groupedProduct.products.map(
        product => product._id || product.productInfo._id,
      ))
    return displayedProductIds.every(id => indexes.value.includes(id))
  })

  const selectAllProducts = (allProductIds, selectEventTrack) => {
    const currentIndexesSet = new Set(indexes.value)
    const newIndexesSet = new Set([...currentIndexesSet, ...allProductIds])
    indexes.value = Array.from(newIndexesSet)
    if (selectEventTrack) selectEventTrack()
  }

  const deselectAllProducts = (
    allProductIds,
    unselectEventTrack,
    forceSelect,
  ) => {
    if (unselectEventTrack) unselectEventTrack()

    if (forceSelect === false) {
      indexes.value = []
    } else {
      indexes.value = indexes.value.filter(id => !allProductIds.includes(id))
    }
  }

  const onToggleSelectAll = async ({
    forceSelect = null,
    selectEventTrack,
    unselectEventTrack,
    selectedWishlistId,
  }) => {
    const shouldSelectAll = forceSelect !== null ? forceSelect : !isSelectedAllOnScreen.value
    store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, shouldSelectAll)

    const allProductIds = await fetchProductIdsUnderCurrentFilters()
    if (allProductIds.length === 0 && !shouldSelectAll) {
      deselectAllProducts([], null, forceSelect)
      toggleRemoveAllBtn(false)
      return
    }

    if (shouldSelectAll && allProductIds.length > 0) {
      selectAllProducts(allProductIds, selectEventTrack)
      toggleRemoveAllBtn(!!selectedWishlistId)
    } else {
      deselectAllProducts(allProductIds, unselectEventTrack, forceSelect)
      toggleRemoveAllBtn(false)
    }
  }

  return {
    onToggleSelectAll,
    isSelectedAllOnScreen,
  }
}
