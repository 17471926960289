<template>
  <div
    class="cart-error-toast-container mb-1"
  >
    <feather-icon
      class="cursor-pointer x-icon"
      icon="XIcon"
      size="20"
      @click="handleCrossIconClick"
    />

    <div class="h-100 d-flex justify-content-between align-items-center">
      <div class="h-100 d-flex align-items-center ml-1">
        <alert-icon-svg />
        <div class="ml-2">
          <div class="error-title">
            {{ errorTitle }}
          </div>
          <span class="error-desscription">Remove them from the cart. After the removal you can proceed with the order.</span>
        </div>
      </div>
      <div
        v-if="shouldShowRemoveAllBtn"
        class="mr-3"
      >
        <div
          class="remove-btn"
          @click="handleRemoveAllClick"
        >
          <b-spinner
            v-if="isRemovingItems"
            small
          />
          <span v-else>
            {{ removeAllBtnTitle }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AlertIconSvg from '@/@core/assets/svg-components/AlertIconSvg.vue'
import { BSpinner } from 'bootstrap-vue'

export default {
    name: 'CartErrorPopup',
    components: { AlertIconSvg, BSpinner },
    emits: [
      'on-cross-icon-click',
      'on-remove-all-click',
    ],
    props: {
      errorTitle: {
        type: String,
        default: 'Some items have errors',
      },
      removeAllBtnTitle: {
        type: String,
        default: 'Remove all',
      },
      isRemovingItems: {
        type: Boolean,
        default: false,
      },
      shouldShowRemoveAllBtn: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      handleCrossIconClick() {
        this.$emit('on-cross-icon-click')
      },
      handleRemoveAllClick() {
        this.$emit('on-remove-all-click')
      },
    },
}
</script>

<style lang="scss" scoped>
$error-color: #AF504B;

.cart-error-toast-container {
    width: 100%;
    height: 69px;
    background: #FFD9D6;
    color: $error-color;
    border: 3px $error-color solid;
    position: relative;

    .x-icon {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    .error-title {
        color: $error-color;
        font-size: 20px;
        font-weight: 600;
        word-wrap: break-word;
    }

    .error-desscription {
        color: $error-color;
        font-size: 14px;
        font-weight: 400;
        word-wrap: break-word
    }

    .remove-btn {
        height: 36px;
        padding-left: 16px;
        padding-right: 16px;
        background: $error-color;
        justify-content: center;
        align-items: center;
        gap: 8px;
        display: inline-flex;

        text-align: center;
        color: #F4F4F4;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
        cursor: pointer;

        &:hover {
            opacity: 0.95;
        }
    }
}
</style>
