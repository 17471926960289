<template>
  <div class="lead-ai-page">
    <div class="content-container">
      <!-- Filter Section -->
      <div>
        <LeadAIFilter
          :allow-search="true"
          :allow-filter-options="true"
          :search-text.sync="searchText"
          :selected-country.sync="selectedCountry"
          :selected-store-types.sync="selectedStoreTypes"
          :selected-categories.sync="selectedCategories"
          :selected-retail-prices.sync="selectedRetailPrices"
          @searchData="searchLeads"
          @filterChanged="handleFilterChange"
          @resetFilters="handleResetFilters"
        />
      </div>
      <div class="main-content">
        <div
          v-if="!isGenAiEnabled"
          class="header-section"
        >
          <h1>
            <span class="underline-text">Supercharge Yo</span><span class="heading-text">ur Sales Game</span><span
              class="highlight-text highlight-text-color"
            ><b> with Leads AI </b></span>
          </h1>
          <p class="mt-2">
            Leads AI powers up your pipeline by connecting you with top-tier
            buyers through smart insights,<br>
            so you can
            <span class="highlight-text-color"><b> lock in on leads that boost your bottom line! </b></span>
          </p>
        </div>
        <!-- Grid Section -->
        <div
          v-if="loading"
          class="spinner-container"
        >
          <b-spinner label="Loading..." />
        </div>
        <LeadAIGrid
          v-else
          :leads="leads"
          :total-leads="totalLeads"
          :current-page="currentPage"
          :page-size="pageSize"
          :filter-failed="filterFailed"
          :search-text="searchText"
          @pageChanged="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import LeadAIFilter from '@/views/apps/lead-ai/LeadAIFilter.vue'
import { BSpinner } from 'bootstrap-vue'
import LeadAIGrid from '@/views/apps/lead-ai/LeadAIGrid.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { onBeforeUnmount, onMounted } from '@vue/composition-api'
import store from '@/store'
import { Settings } from '@/Settings'
import useLeadAI from '../useLeadAI'

export default {
  name: 'LeadsDatabase',
  components: {
    LeadAIFilter,
    LeadAIGrid,
    BSpinner,
  },
  data() {
    return {
      isGenAiEnabled: Settings.leadsAiGenAiEnabled(),
    }
  },
  setup() {
    const {
      leads,
      totalLeads,
      pageSize,
      loading,
      filterFailed,
      selectedCategories,
      selectedCountry,
      selectedRetailPrices,
      selectedStoreTypes,
      currentPage,
      searchText,
      fetchLeads,
      handlePageChange,
      searchLeads,
      handleFilterChange,
      handleResetFilters,
      validateFilters,
    } = useLeadAI({ dispatch: store.dispatch, state: store.state.leadai, commit: store.commit })
    onMounted(() => {
      validateFilters()
      fetchLeads()
    })

    const startTime = Date.now()

    onBeforeUnmount(() => {
      analytics.track(constants.TRACKS.PAGES.BRAND_OPENS_LEAD_AI_LIST, {
        timeSpent: `${(Date.now() - startTime) / 1000 / 60} minutes`,
      })
    })

    return {
      leads,
      totalLeads,
      pageSize,
      loading,
      filterFailed,
      selectedCategories,
      selectedCountry,
      selectedRetailPrices,
      selectedStoreTypes,
      currentPage,
      searchText,
      fetchLeads,
      handlePageChange,
      searchLeads,
      handleFilterChange,
      handleResetFilters,
    }
  },
}
</script>
<style scoped>
.lead-ai-page {
  padding-right: 20px;
}

.content-container {
  display: flex;
  gap: 20px;
}

.main-content {
  flex: 3;
}

.header-section {
  text-align: left;
  margin-bottom: 20px;
}

.underline-text,
.highlight-text {
  font-family: Manrope, sans-serif;
  font-size: 32.47px;
  font-weight: 400;
  line-height: 41.49px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.heading-text {
  font-family: Manrope, sans-serif;
  font-size: 32.47px;
  font-weight: 400;
  text-align: left;
}

.underline-text {
  border-bottom: 2px solid currentColor;
  /* Adjust the thickness as needed */
  padding-bottom: 2px;
  /* Adjust the space as needed */
}

.highlight-text-color {
  color: #8D82FD;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px !important;
}
</style>
