<template>
  <b-card
    :id="`tooltip_products_error_${collection._id}`"
    class="mb-2 collection-card"
    :class="{'no-products-collection border-danger': isErrorInProducts}"
    no-body
  >
    <b-link
      id="collection0"
      :to="{ name: 'collection/products', params: { collectionId: collection._id } }"
    >
      <b-img
        :src="collection.logo || getDefaultProductImage(300, 300)"
        class="collection-logo"
      />
      <b-card-body class="p-75 position-relative">
        <b-dropdown
          v-if="isCollectionListPage"
          no-caret
          class="collection-actions-dropdown"
          variant="none"
          toggle-class="p-50"
          right
          @click.stop.prevent
        >
          <template #button-content>
            <div class="collection-actions-toggle">
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
              />
            </div>
          </template>
          <b-dropdown-item
            v-if="isEntityAdmin"
            :to="{ name: 'collection/edit', params: { id: collection._id } }"
            @click="beforeEdit(collection)"
          >
            Edit Collection
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isEntityAdmin"
            @click="showModal(collection)"
          >
            Update Availability
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isEntityAdmin"
            @click="showCollectionWarningModal(collection)"
          >
            Delete Collection
          </b-dropdown-item>
          <b-dropdown-item @click="openDownloadModal(collection._id)">
            Download Collection
          </b-dropdown-item>
          <b-dropdown-item @click="copyCollectionLink(collection)">
            Copy Collection’s Link
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isEntityAdmin"
            @click="updateCollectionStatus(collection.status)"
          >
            <span :class="isCollectionLive(collection.status) ?'text-danger' :'text-success'"> Change to {{ isCollectionLive(collection.status) ? COLLECTION_STATUS.EXPIRED.LABEL : COLLECTION_STATUS.LIVE.LABEL }}? </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isEntityAdmin"
            @click="$refs.collectionModals.getCollectionPriceBook(true)"
          >
            Price Books
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isEntityAdmin"
            @click="$refs.collectionModals.openCurrencyBook()"
          >
            Currency Books
          </b-dropdown-item>
          <!-- <b-dropdown-item
            v-if="isEnableSync"
            @click="syncWithShopify(collection)"
          >
            Sync with Shopify
          </b-dropdown-item> -->
        </b-dropdown>
        <div class="title d-flex align-items-center justify-content-between mr-25">
          <span> {{ collection.name }} </span>
          <b-button
            v-if="isRetailer"
            variant="outline-none"
            class="download-btn"
            @click.stop.prevent="$bvModal.show(`download_catalog_${collection._id}`)"
          >
            Download
          </b-button>
        </div>

        <div
          v-if="isCollectionListPage"
          class="mt-50"
        >
          {{ collection.productsCount || 0 }} Product{{ collection.productsCount > 1 ? 's' : '' }}
        </div>
        <div
          v-if="collection.type && !isPreviousCollection"
          class="mt-50 collection-type"
          :class="parseCollectionTypeClass(collection.type)"
        >
          {{ getCollectionTypeText(collection.type) }}
        </div>
        <div
          v-if="isPreviousCollection"
          class="mt-50 collection-type closed-bg"
        >
          Deadline Closed
        </div>
        <div class="mt-50">
          {{ parseCollectionDate(collection.endDate) }}
        </div>
        <div
          v-if="isCollectionListPage"
          class="mt-50"
        >
          Updated {{ formattedDate(collection.updatedAt) }}
        </div>
        <div
          v-if="collection.expectedDeliveryWindow && collection.expectedDeliveryWindow.fromDate && collection.expectedDeliveryWindow.toDate"
          class="mt-50"
        >
          Delivery window: {{ getMonthAndYearFromDate(collection.expectedDeliveryWindow.fromDate) }} - {{ getMonthAndYearFromDate(collection.expectedDeliveryWindow.toDate) }}
        </div>
        <div
          class="mt-50"
        >
          <span :class="readMore ? '':'read-more-text'"> Description: {{ getDescription(collection.description) }} </span>
          <span
            v-if="collection.description.length > DESCRIPTION_MAX_SHOWING_LENGTH"
            class="read-more"
            @click.stop.prevent="readMore = !readMore"
          > Read {{ readMore ? 'Less' : 'More' }} </span>
        </div>
        <!-- Disabling this temporarily -->
        <b-button
          v-if="false"
          class="w-100"
          variant="custom-primary"
        >
          Download LookBook
        </b-button>
      </b-card-body>
    </b-link>
    <dowload-catalog :collection="collection" />
    <collection-modals
      ref="collectionModals"
      :fetch-collections="fetchCollections"
      :collection="collection"
    />
    <b-tooltip
      v-if="isErrorInProducts"
      class="font-Inter"
      :target="`tooltip_products_error_${collection._id}`"
      placement="bottom"
      triggers="hover"
      variant="danger"
    >
      {{ PRODUCTS_ERROR_MESSAGE }}
    </b-tooltip>
    <b-modal
      id="modal-select"
      ref="collectionWarning"
      size="lg"
      modal-class="modal-primary custom-model"
      centered
      hide-header-close
      hide-header
      title="Collection Warning"
      hide-footer
    >
      <div>
        <div class="pb-50 border-bottom">
          <span class="custom-modal-heading">Collection Warning </span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="closeCollectionWarningModal"
          />
        </div>
        <p
          class="mt-1 text-center product-message"
          v-html="collectionRemoveMessage"
        />
      </div>
      <div>
        <div class="text-center mt-1">
          <b-button
            v-show="canDeleteCollection"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-2 mr-2 px-sm-3 font-weight-bolder"
            variant="outline-info"
            @click="closeCollectionWarningModal"
          >
            Cancel
          </b-button>
          <b-button
            v-show="canDeleteCollection"
            type="submit"
            variant="info"
            class="mt-2 px-sm-3 font-weight-bolder"
            :disabled="isSaving"
            @click="removeCollection"
          >
            {{
              isSaving ? 'Removing Collection...' : 'Yes, remove the collection'
            }}
          </b-button>
          <b-button
            v-show="!canDeleteCollection"
            type="reset"
            variant="info"
            class="mt-2 px-sm-3 font-weight-bolder"
            :disabled="isSaving"
            @click="closeCollectionWarningModal"
          >
            Ok! I understand
          </b-button>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
 BCard, BImg, BLink, BCardBody, BButton, BDropdown, BDropdownItem, BTooltip,
} from 'bootstrap-vue'
import {
  parseCollectionDate,
  parseCollectionTypeClass,
  getCollectionTypeText,
  getDefaultProductImage,
  formatObject,
} from '@/@core/utils/utils'
import analytics, { getCollectionMixpanelObj } from '@/@core/utils/analytics'
import constants, { PRODUCTS_ERROR_MESSAGE } from '@/constants'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { DELTE_COLLECTION, SYNC_COLLECTION_WITH_SHOPIFY, UPDATE_COLLECTION } from '@/store/modules/collection.module'
import store from '@/store'
import { convertToHtml } from '@/common-utils'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'

import CollectionModals from '../../collection/CollectionModals.vue'
import DowloadCatalog from '../../collection/DowloadCatalog.vue'
import UserRoleMixin from '../../UserRoleMixin.vue'

const { formattedDate, getMonthAndYearFromDate } = utils

const { COLLECTION_STATUS } = c

export default {
  components: {
    BCard,
    BImg,
    BLink,
    BCardBody,
    BButton,
    DowloadCatalog,
    CollectionModals,
    BDropdown,
    BDropdownItem,
    BTooltip,
  },
  mixins: [UserRoleMixin],
  props: {
    collection: {
      type: Object,
      default: () => {},
    },
    isPreviousCollection: {
      type: Boolean,
      default: false,
    },
    fetchCollections: {
      type: Function,
      default() {
      },
    },
  },
  data() {
    return {
      getCollectionTypeText,
      parseCollectionDate,
      parseCollectionTypeClass,
      getDefaultProductImage,
      getMonthAndYearFromDate,
      canDeleteCollection: false,
      isSaving: false,
      collectionRemoveMessage: '',
      collectionId: null,
      selectedCollection: {},
      PRODUCTS_ERROR_MESSAGE,
      formattedDate,
      COLLECTION_STATUS,
      readMore: false,
      DESCRIPTION_MAX_SHOWING_LENGTH: 50,
    }
  },
  computed: {
    isCollectionListPage() {
      return this.$route.name === 'collections'
    },
    isErrorInProducts() {
      return this.isBrand && this.isCollectionListPage && this.collection.productsCount === 0
    },
  },
  methods: {
    getDescription(description) {
      if (this.readMore) {
        return description
      }
      return description.slice(0, this.DESCRIPTION_MAX_SHOWING_LENGTH)
    },
    async showCollectionWarningModal(collection) {
      this.collectionId = collection._id
      await this.checkRemoveCollection()
    },
    beforeEdit(collection) {
      analytics.track(constants.TRACKS.ACTIONS.BRAND_STARTS_EDITING_COLLECTION, formatObject(getCollectionMixpanelObj(collection)))
    },
    copyCollectionLink(collection) {
      navigator.clipboard.writeText(`${window.location.href.replace('collections', '')}products/${collection._id}`).then(() => {
        apiToastSuccess('Collection Link Copied')
      }).catch(err => {
        apiToastWarning(err)
      })
    },
    openDownloadModal(collectionId) {
      this.$bvModal.show(`download_catalog_${collectionId}`)
    },
    showModal(collection) {
      this.$bvModal.show(`updateavailability_${collection._id}`)
      analytics.track(constants.TRACKS.ACTIONS.BRAND_STARTS_UPDATING_AVAILABILITY_DATES, formatObject(getCollectionMixpanelObj(collection)))
    },
    closeCollectionWarningModal() {
      this.$refs.collectionWarning.hide()
    },
    async removeCollection() {
      this.isSaving = true
      if (this.canDeleteCollection) {
        const params = {
            shouldProceed: true,
        }
        store.dispatch(DELTE_COLLECTION, { collectionId: this.collectionId, params })
          .then(res => {
            apiToastSuccess(res.data.message)
            this.isSaving = false
            this.closeCollectionWarningModal()
            this.fetchCollections()
          })
          .catch(err => {
            this.isSaving = false
            apiToastWarning(err)
          })
      }
      else {
        this.isSaving = false
        apiToastWarning('Collection Not Valid')
      }
    },
    async syncWithShopify(collection) {
      store.dispatch(SYNC_COLLECTION_WITH_SHOPIFY, collection._id)
        .then(res => {
          apiToastSuccess(res.data.message)
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
    async checkRemoveCollection() {
      this.isSaving = true
      if (this.collectionId) {
        store.dispatch(DELTE_COLLECTION, { collectionId: this.collectionId })
          .then(res => {
            apiToastSuccess(res.data.message)
            this.isSaving = false
            this.closeCollectionWarningModal()
          })
          .catch(err => {
            const { response } = err
            const { data } = response || {}
            const canDelete = data?.data
            const { message } = data
            this.collectionRemoveMessage = convertToHtml(message)
            this.canDeleteCollection = !!canDelete?.shouldProceed
            this.$refs.collectionWarning.show()
            this.isSaving = false
          })
      }
      else {
        this.isSaving = false
        apiToastWarning('Collection Not Valid')
      }
    },
    isCollectionLive(status) {
      return status === COLLECTION_STATUS.LIVE.VALUE
    },
    updateCollectionStatus(status) {
      const payload = {}
      if (this.isCollectionLive(status)) {
        payload.status = COLLECTION_STATUS.EXPIRED.VALUE
      }
      else {
        payload.status = COLLECTION_STATUS.LIVE.VALUE
      }
      this.$store.dispatch(UPDATE_COLLECTION, { collectionId: this.collection._id, payload })
        .then(res => {
          apiToastSuccess(res.data.message)
          this.fetchCollections()
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-brand-preview.scss';
@import '~@core/scss/base/pages/app-collection.scss';
</style>
