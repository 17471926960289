export const dromoFields = [
  {
      key: 'style_code',
      label: 'Style Code',
      type: 'string',
      requireMapping: true,
      validators: [{
          validate: 'required',
      }],
  },
  {
      key: 'parent_id',
      label: 'Parent ID',
      type: 'string',
      requireMapping: true,
  },
  {
      key: 'sku',
      label: 'SKU',
      type: 'string',
      requireMapping: true,
      validators: [{
          validate: 'required',
      }],
  },
  {
      key: 'color_family',
      label: 'Color Family',
      type: 'string',
  },
  {
      key: 'color',
      label: 'Color',
      type: 'string',
  },
  {
      key: 'size',
      label: 'Size',
      type: 'string',
      manyToOne: true,
      requireMapping: true,
  },
  {
      key: 'department',
      label: 'Department',
      type: 'string',
  },
  {
      key: 'category',
      label: 'Category',
      type: 'string',
  },
  {
      key: 'sub_category',
      label: 'Sub Category',
      type: 'string',
  },
  {
      key: 'product_name',
      label: 'Product Name',
      type: 'string',
  },
  {
      key: 'product_description',
      label: 'Product Description',
      type: 'string',
  },
  {
      key: 'material_composition',
      label: 'Material Composition',
      type: 'string',
  },
  {
      key: 'gender',
      label: 'Gender',
      type: 'string',
  },
  {
      key: 'is_it_a_pack',
      label: 'Is It a Pack?',
      type: 'string',
  },
  {
      key: 'sku_qty_in_pack',
      label: 'SKU Qty in Pack',
      type: 'string',
  },
  {
      key: 'min_qty_per_size',
      label: 'Min Qty Per Size',
      type: 'string',
  },
  {
      key: 'min_qty_per_style',
      label: 'Min Qty Per Style',
      type: 'string',
  },
  {
      key: 'inventory',
      label: 'Inventory',
      type: 'string',
  },
  {
      key: 'currency',
      label: 'Currency',
      type: 'string',
  },
  {
      key: 'wholesale_price',
      label: 'Wholesale Price',
      type: 'number',
  },
  {
      key: 'retail_price',
      label: 'Retail Price',
      type: 'number',
  },
  {
      key: 'images_1',
      label: 'Images/1',
      type: 'string',
      validators: [{
          validate: 'required',
      }],
  },
  {
      key: 'images_2',
      label: 'Images/2',
      type: 'string',
  },
  {
      key: 'images_3',
      label: 'Images/3',
      type: 'string',
  },
  {
      key: 'images_4',
      label: 'Images/4',
      type: 'string',
  },
  {
      key: 'images_5',
      label: 'Images/5',
      type: 'string',
  },
  {
      key: 'product_order',
      label: 'Product Order',
      type: 'string',
  },
  {
      key: 'country_of_origin',
      label: 'Country of Origin',
      type: 'string',
  },
  {
      key: 'hs_code',
      label: 'HS Code',
      type: 'string',
  },
  {
      key: 'barcode',
      label: 'Barcode',
      type: 'string',
  },
  {
      key: 'shipping_start_date',
      label: 'Shipping Start Date',
      type: 'string',
  },
  {
      key: 'shipping_end_date',
      label: 'Shipping End Date',
      type: 'string',
  },
  {
      key: 'lines',
      label: 'Lines',
      type: 'string',
  },
  {
      key: 'sub_lines',
      label: 'Sub Lines',
      type: 'string',
  },
  {
      key: 'groups',
      label: 'Groups',
      type: 'string',
  },
  {
      key: 'sub_groups',
      label: 'Sub Groups',
      type: 'string',
  },
  {
      key: 'key_styles',
      label: 'Key Styles',
      type: 'string',
  },
  {
      key: 'keywords',
      label: 'Keywords',
      type: 'string',
  },
  {
      key: 'recommended_items',
      label: 'Recommended Items',
      type: 'string',
  },
]
